import { forwardRef, useRef, useState } from "react";
import { Box, Checkbox, IconButton, TextField } from "@mui/material";
import "./FileInputComponent.css";
import { MdClose, MdCloudUpload, MdDelete } from "react-icons/md";
import { loadFile } from "../../utils/loadFile";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
const FileInputComponent = forwardRef(
  (
    {
      type,
      id,
      label,
      name,
      onChange,
      onBlur,
      value,
      errors,
      err,
      touched,
      isMultifile = false,
      accept = "",
      isUpdate = false,
    },
    ref
  ) => {
    const [previewFiles, setPreviewFiles] = useState(value?.images);
    const [selectedThumbnail, setSelectedThumbnail] = useState(0);
    const fileUploaderRef = useRef();
    const handleAttachFile = (e) => {
      if (fileUploaderRef.current) {
        fileUploaderRef.current.click();
      }
    };

    const handleFileChange = (e) => {
      const files = e.target.files;
      if (files.length > 0) {
        const previewImage = [];
        const newFiles = Array.from(files);
        newFiles?.map((file) => {
          previewImage.push(file);
        });
        const newFilesArray = Array.from(files).map((file, index) => ({
          src: file,
          alt: file.name,
          isThumbnail: value?.images?.length > 0 ? false : index === 0,
          magnifySrc: "",
        }));
        setPreviewFiles((prevPreviewFiles) => [
          ...prevPreviewFiles,
          ...previewImage,
        ]);
        if (onChange) {
          onChange({
            target: {
              name,
              value: [...value?.images, ...newFilesArray],
            },
          });
        }
      }
    };
    const handleThumbnailSelect = (index) => {
      setSelectedThumbnail(index);
      const updatedFiles = value?.images?.map((file, idx) => ({
        ...file,
        isThumbnail: idx === index ? true : false,
      }));
      if (onChange) {
        onChange({ target: { name, value: updatedFiles } });
      }
    };
    // const deleteImage = (index) => {
    //   const updatedPreviewFiles = previewFiles.filter(
    //     (_, idx) => idx !== index
    //   );
    //   setPreviewFiles(updatedPreviewFiles);
    //   const updatedFile = value?.images.filter((_, idx) => idx !== index);
    //   if (onChange) {
    //     onChange({
    //       target: { name, value: updatedFile },
    //     });
    //   }
    // };
    const deleteImage = (index) => {
      const isDeletedThumbnail = value?.images[index]?.isThumbnail;
      if (previewFiles?.length > 0) {
        const updatedPreviewFiles = previewFiles.filter(
          (_, idx) => idx !== index
        );
        setPreviewFiles(updatedPreviewFiles);
      }
      const updatedFiles = [
        ...value?.images?.map((image) => ({ ...image })),
      ].filter((_, idx) => idx !== index);

      if (isDeletedThumbnail && updatedFiles.length > 0) {
        updatedFiles[0].isThumbnail = true;
        setSelectedThumbnail(0);
      }
      if (onChange) {
        onChange({
          target: { name, value: updatedFiles },
        });
      }
    };
    return (
      <>
        <Box className="file-input-contanier">
          <Box className="file-upload-contanier" onClick={handleAttachFile}>
            <MdCloudUpload />
            <span>Upload Image</span>
          </Box>
          <TextField
            type={type}
            className="input-file-field"
            id={id}
            label={label}
            name={name}
            variant="outlined"
            onChange={handleFileChange}
            onBlur={onBlur}
            // value={isMultifile ? "" : value}
            InputLabelProps={{
              shrink: true,
              sx: { lineHeight: "unset", top: "-5px" },
            }}
            inputProps={{
              autoComplete: "new-password",
              multiple: isMultifile,
              accept,
              ref: (el) => {
                fileUploaderRef.current = el;
                if (ref) {
                  if (typeof ref === "function") {
                    ref(el);
                  } else if (
                    typeof ref === "object" &&
                    ref.hasOwnProperty("current")
                  ) {
                    ref.current = el;
                  }
                }
              },
            }}
            InputProps={{
              sx: {
                "& input": {
                  padding: "10px 12px",
                  fontSize: "14px",
                },
                borderRadius: "8px",
              },
            }}
          />
          <Box className="preview-files-main-contanier">
            {value?.images.map((file, index) => {
              let src;
              const altText = file.alt;
              if (file.src instanceof File) {
                src = URL.createObjectURL(file.src);
              } else {
                src = loadFile(file?.src);
              }
              return (
                <Box
                  className="preview-file-contanier"
                  key={index}
                  sx={{ position: "relative" }}
                >
                  <img className="preview-file" src={src} alt={altText} />
                  <Checkbox
                    id={`product-image-thumbnail-${index}`}
                    name={`product-image-thumbnail-${index}`}
                    checked={selectedThumbnail === index}
                    icon={
                      <RadioButtonUncheckedIcon
                        sx={{ fontSize: 24, color: "black" }}
                      />
                    }
                    checkedIcon={
                      <CheckCircleIcon
                        sx={{ fontSize: 24, color: "rgb(193, 118, 31)" }}
                      />
                    }
                    onChange={() => handleThumbnailSelect(index)}
                    sx={{
                      position: "absolute",
                      top: 3,
                      left: 3,
                      color: "black",
                      padding: 0,
                      backgroundColor: "white",
                      "& .MuiSvgIcon-root": { fontSize: 20 },
                      "&:hover": {
                        backgroundColor: "white",
                      },
                    }}
                  />
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: -8,
                      right: -4,
                      color: "white",
                      padding: "2px",
                      backgroundColor: "#ff5b57",
                      "&:hover": {
                        backgroundColor: "#ff5b57",
                      },
                    }}
                    onClick={() => deleteImage(index)}
                  >
                    <MdClose size="12px" />
                  </IconButton>
                </Box>
              );
            })}
          </Box>
        </Box>
        {errors && touched && <Box className="input-error">{errors}</Box>}
        {err && <Box className="input-error">{err}</Box>}
      </>
    );
  }
);

export default FileInputComponent;
