import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Avatar,
  Button,
} from "@mui/material";
import { MdOutlineMenu } from "react-icons/md";

import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import { getLocalStorageItem } from "../../utils/localStorage";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";

const Navbar = ({
  isSidebarOpen,
  setIsSidebarOpen,
  currentTheme,
  toggleTheme,
}) => {
  const { firstName, lastName } = getLocalStorageItem("user", true);
  const navigate = useNavigate();
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const logoutHandle = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <AppBar position="static" sx={{ bgcolor: "#fff" }}>
      <Toolbar
        className="navbar"
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "#fff",
            color: "#000",
          },
        }}
      >
        <Box className="navbar-title">
          <Typography variant="subtitle1" noWrap>
            Auction Admin
          </Typography>
          <Box className="sidebar-btn" onClick={toggleSidebar}>
            <MdOutlineMenu />
          </Box>
        </Box>
        <Box className="navbar-profile">
          <Avatar alt={firstName} sx={{ mr: 1 }}>
            {firstName[0].toUpperCase() || (
              <AccountCircleRoundedIcon fontSize="large" />
            )}
          </Avatar>
          <Typography variant="body1">{`${firstName} ${lastName}`}</Typography>
          <Typography sx={{ paddingLeft: "15px" }}>
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={logoutHandle}
            >
              Logout
            </Button>
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
