import { Box, InputLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./RichTextEditorComponent.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const RichTextBoxEditorComponent = ({
  id,
  label,
  name,
  value,
  onChange,
  onBlur,
  errors,
  touched,
  isUpdate = false,
}) => {
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["link", "image", "video", "formula"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];

  const handleChange = (newValue) => {
    onChange({
      target: {
        name: name,
        value: newValue,
      },
    });
  };

  return (
    <Box className="rich-text-contanier">
      <InputLabel id={id} className="rich-text-label">
        {label}
      </InputLabel>

      <ReactQuill
        theme="snow"
        className="rich-text-body"
        id={id}
        value={value}
        onChange={handleChange}
        modules={{ toolbar: toolbarOptions }}
      />
      {errors && touched && <Box className="input-error">{errors}</Box>}
    </Box>
  );
};

export default RichTextBoxEditorComponent;
