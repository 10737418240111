import { gql } from "@apollo/client";

export const GET_CATEGORIES_QUERY = gql`
  query GetCategories($categoryInput: GetCategoriesInput) {
    getCategories(CategoryInput: $categoryInput) {
      totalPages
      total
      currentPage
      currentLimit
      categories {
        _id
        name
        parentId
        deleted
        isFilterApplicable
        subCategories {
          _id
          name
          parentId
          deleted
          isFilterApplicable
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
