import React, { useEffect, useMemo, useState } from "react";
import TableComponent from "../../../components/TableComponent";
import { Avatar, Box, Grid } from "@mui/material";
import SearchComponent from "../../../components/SearchComponent";
import BreadcrumbsComponent from "../../../components/BreadcrumbsComponent";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GETPRODUCTS_QUERY } from "../query";
import { errorToaster } from "../../../components/Toaster";
import { loadFile } from "../../../utils/loadFile";

const SoldProduct = () => {
  const breadcrumbsArray = [{ title: "Sold Product" }];

  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const {
    loading,
    error,
    data,
    refetch: getSoldProduct,
  } = useQuery(GETPRODUCTS_QUERY, {
    variables: {
      productInput: {
        page: 1,
        limit: pageSize,
        categoryId: "",
        search: "",
        filters: ["SOLD"],
      },
    },
  });

  if (error) {
    errorToaster(error.message);
  }

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  useEffect(() => {
    setProducts(data?.getProducts?.products || []);
  }, [data]);

  useEffect(() => {
    getSoldProduct({
      productInput: { page: currentPage, limit: pageSize, filters: ["SOLD"] },
    });
  }, [getSoldProduct, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleViewProduct = (productId) => {
    navigate(`/products/all-product/view-product/${productId}`);
  };

  const handleDisplayProduct = async (productId, value) => {
    getSoldProduct({
      isSold: value,
      productId: productId,
      productInput: { filters: ["SOLD"] },
    });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "thumbnail",
        header: "#",
        minSize: 100,
        maxSize: 400,
        size: 50,
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => {
          const thumbnailImage = row?.original?.thumbnailImage;
          return (
            <Avatar className="avatar-contanier" variant="rounded">
              <img
                className="avatar-image"
                src={loadFile(thumbnailImage?.src)}
                alt={thumbnailImage?.alt}
              />
            </Avatar>
          );
        },
      },
      {
        accessorKey: "displayName",
        header: "Product Name",
      },
      {
        accessorKey: "category.name",
        header: "Category",
      },
    ],
    []
  );

  const handleSearch = (value) => {
    getSoldProduct({ productInput: { search: value, filters: ["SOLD"] } });
  };

  return (
    <Box className="content-contanier">
      <BreadcrumbsComponent breadcrumbs={breadcrumbsArray} />

      <Grid container spacing={2} className="content-filter-container">
        <Grid item xs={6}>
          <SearchComponent handleSearch={handleSearch} />
        </Grid>
      </Grid>

      <TableComponent
        columns={columns}
        data={products}
        hasCopyBtn={true}
        isManualPagination={true}
        rowCount={data?.getProducts?.total || 0}
        handlePageChange={handlePageChange}
        density="compact"
        loader={loader}
        handleDisplayProduct={handleDisplayProduct}
        handleViewProduct={handleViewProduct}
        isShowProductView={true}
      />
    </Box>
  );
};

export default SoldProduct;
