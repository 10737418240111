import axios from "axios";
import { getSecretAccess } from "../api/FileUploadAPI";
import { setLocalStorageItem } from "../utils/localStorage";

const instance = axios.create({
  baseURL: process.env.REACT_APP_FILE_UPLOAD_BASE_URL,
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    console.info("Instance Error", error);
    if (
      error.response.data === "Secret Access Key is Expired." ||
      error.response.status === 401
    ) {
      // const response = await getSecretAccess({
      //   email: "shubhamkanani.dds@gmail.com",
      //   password: "Admin@123"
      // });
      const response = await getSecretAccess({
        email: "dev-support@theauctionhub.ca",
        password: "dev-auctionhub@123",
      });
      if (response.data.accessKeyId && response.data.secretAccessKey) {
        setLocalStorageItem("accessKeyId", response.data.accessKeyId);
        setLocalStorageItem("secretAccessKey", response.data.secretAccessKey);

        const originalRequest = error.config;

        originalRequest.headers[
          "Authorization"
        ] = `Bearer ${response.data.accessKeyId}`;

        return axios(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
