import React from "react";
import "./ButtonComponent.css";
import { Button, CircularProgress } from "@mui/material";

const ButtonComponent = ({
  label,
  type,
  width = "",
  onClick = () => {},
  className = "",
  isLoader = false,
}) => {
  return (
    <Button
      className={`input-button ${className}`}
      type={type}
      variant="contained"
      sx={{
        width: width || "auto",
      }}
      onClick={onClick}
      disabled={isLoader}
    >
      {isLoader ? (
        <>
          <CircularProgress size={24} color="inherit" className="btn-loader" />
          <span>{label}</span>
        </>
      ) : (
        label
      )}
    </Button>
  );
};

export default ButtonComponent;
