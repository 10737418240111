import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { MdWarning } from "react-icons/md";
import "./DeleteModalComponent.css";

const DeleteModalComponent = ({ isDelete, setIsDelete, deleteRecord }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  };

  return (
    <Box>
      <Modal
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
        open={isDelete.visible}
        onClose={() => setIsDelete({ visible: false })}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isDelete.visible}>
          <Box sx={style} className="delete-modal-body">
            <Box className="delte-modal-icon">
              <MdWarning />
            </Box>
            <Typography id="delete-modal-title" variant="h5" component="h1">
              Are you sure?
            </Typography>
            <Typography
              id="delete-modal-description"
              className="delete-modal-content"
            >
              Do you really want to delete this record? This process cannot be
              undone.
            </Typography>
            <Box className="delete-modal-button">
              <Button
                variant="contained"
                className="cancel-btn"
                onClick={() => setIsDelete({ visible: false })}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="delete-btn"
                onClick={() => {
                  deleteRecord(isDelete?.record);
                  setIsDelete({ visible: false });
                }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default DeleteModalComponent;
