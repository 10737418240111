import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { MdAddBox, MdDelete } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { uploadFiles } from "../../../api/FileUploadAPI";
import BreadcrumbsComponent from "../../../components/BreadcrumbsComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import { CardComponent } from "../../../components/CardComponent";
import FileInputComponent from "../../../components/FileInputComponent";
import InputComponent from "../../../components/InputComponent";
import RichTextBoxEditorComponent from "../../../components/RichTextEditorComponent";
import ScrollbarComponant from "../../../components/ScrollbarComponant";
import SelectComponent from "../../../components/SelectComponent";
import { errorToaster, successToaster } from "../../../components/Toaster";
import {
  damageStatus,
  PackagingStatus,
  productStatus,
} from "../../../enums/Constants.enum";
import { Routes as Route } from "../../../enums/Routes.enum";
import { GET_CATEGORIES_QUERY } from "../../Category/query";
import {
  ADDPRODUCT_MUTATION,
  EDITPRODUCT_MUTATION,
  GETPRODUCTS_QUERY,
} from "../query";
import "./AddorEditProduct.css";

export const getCategories = (categories) => {
  const categoriesData = categories.map((category) => {
    return {
      title: category.name,
      value: category._id,
    };
  });
  return categoriesData;
};

const AddorEditProduct = () => {
  const initialSpecification = {
    key: "",
    value: "",
  };

  const initialProductDetail = {
    displayName: "",
    originalName: "",
    description: "",
    estimatedBid: "",
    specification: [initialSpecification],
    sku: "",
    categoryId: "",
    subCategoryId: "",
    vendorName: "",
    display: true,
    productStatus: "",
    packagingStatus: "",
    damageStatus: "",
    conditionNotes: "",
    images: [],
    filterBy: "",
  };

  const params = useParams();
  const [productDetail, setProductDetail] = useState(initialProductDetail);
  const [isUpdate, setIsUpdate] = useState(false);
  const [loader, setLoader] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [showRadio, setShowRadio] = useState(false);
  const navigate = useNavigate();
  const thumbnailImageRef = useRef();

  const DisplayingErrorMessagesSchema = Yup.object().shape({
    displayName: Yup.string().required("Product display name is required."),
    originalName: Yup.string().required("Product original name is required."),
    description: Yup.string().required("Description is required."),
    estimatedBid: Yup.number().required("Estimated bid is required."),
    vendorName: Yup.string().required("Vendor name is required."),
    productStatus: Yup.string().required("Product Status is required."),
    packagingStatus: Yup.string().required("Packaging Status is required."),
    categoryId: Yup.string().required("Category is required."),
    images: Yup.array()
      .min(1, "At least one image is required")
      .required("Images are required"),
    ...(subCategoryOptions.length
      ? {
          subCategoryId: Yup.string().required("Sub Category is required."),
        }
      : {}),

    ...(showRadio
      ? {
          filterBy: Yup.string().required("Filter is required"),
        }
      : {}),
    conditionNotes: Yup.string().required("Condition Note is required"),
  });

  const { data: categories } = useQuery(GET_CATEGORIES_QUERY, {
    variables: { page: 1, limit: 10 },
  });

  const {
    error,
    data,
    refetch: getProduct,
  } = useQuery(GETPRODUCTS_QUERY, {
    variables: { productInput: { id: params?.productId } },
  });
  if (error) {
    errorToaster(error.message);
  }
  const [addProduct] = useMutation(ADDPRODUCT_MUTATION);
  const [editProduct] = useMutation(EDITPRODUCT_MUTATION);
  const breadcrumbsArray = [
    { title: "Product", link: "/products/all-product" },
    {
      title: `${
        isUpdate || params?.productId ? "Edit Product" : "Add Product"
      }`,
    },
  ];

  const getSubCategories = useCallback(
    (categoryId) => {
      if (categoryId && categories?.getCategories?.categories && categoryId) {
        const category = categories.getCategories.categories.find(
          (category) => category._id === categoryId
        );

        const subCategories = category?.subCategories || [];
        const finalSubCategories =
          subCategories.length &&
          subCategories.map((subCategory) => {
            return {
              title: subCategory.name,
              value: subCategory._id,
            };
          });

        return finalSubCategories;
      }
      return [];
    },
    [categories]
  );

  useEffect(() => {
    if (categories?.getCategories?.categories?.length > 0) {
      const categoriesArray = getCategories(
        categories?.getCategories?.categories
      );
      setCategoryOptions([...categoriesArray]);
    }
  }, [categories]);

  const productStatusOptions = [
    { title: "New Item", value: productStatus.NewItem },
    { title: "Open ( Appear New )", value: productStatus.OpenAppearNew },
    {
      title: "Open ( Contents Brand New )",
      value: productStatus.OpenContentsBrandNew,
    },
    { title: "Lightly Used or Worn", value: productStatus.LightlyUsedOrWorn },
    { title: "Handled / Used", value: productStatus.HandledUsed },
    { title: "As-is (Expired)", value: productStatus.AsIsExpired },
  ];

  const packagingStatusOption = [
    { title: "Brand Sealed", value: PackagingStatus?.BrandSealed },
    {
      title: "Retail Packaging (Distressed)",
      value: PackagingStatus?.RetailPackagingDistressed,
    },
    {
      title: "Sealed But Distressed Packaging",
      value: PackagingStatus?.SealedButDistressedPackaging,
    },
    {
      title: "No packaging or repackaged",
      value: PackagingStatus?.NoPackagingOrRepackaged,
    },
  ];

  const damageStatusOption = [
    {
      title: "With issue (Red Warning mark)",
      value: damageStatus?.withIssue,
    },
    {
      title: "Cosmetic imperfection (Yellow Warning mark)",
      value: damageStatus?.cosmeticImperfection,
    },
  ];
  useEffect(() => {
    if (params?.productId && data?.getProducts) {
      const {
        category,
        status,
        specification,
        filterBy,
        images,
        thumbnailImage,
        ...rest
      } = data?.getProducts?.products[0];
      const specificationData = specification
        ? Object.entries(specification).map(([key, value]) => {
            return { key, value };
          })
        : [];
      const finalImages = [
        { ...thumbnailImage, isThumbnail: true },
        ...images.map((image) => ({ ...image, isThumbnail: false })),
      ].filter(Boolean);

      setProductDetail({
        ...rest,
        images: finalImages,
        categoryId: category.parentId ? category.parentId : category?._id,
        subCategoryId: category.parentId ? category?._id : null,
        productStatus: status?.ProductStatus,
        packagingStatus: status?.PackagingStatus,
        damageStatus: status?.DamageStatus,
        conditionNotes: status?.conditionNotes,
        specification: specificationData,
        filterBy,
      });
      if (category.parentId) {
        setSubCategoryOptions(getSubCategories(category.parentId));
      }
      if (filterBy !== null) {
        setShowRadio(true);
      }
      setIsUpdate(true);
    }
  }, [data, getSubCategories, params?.productId]);

  const uploadAllImages = async (product) => {
    try {
      const uploadImgFormData = new FormData();
      uploadImgFormData.append("bucket", "Product");
      let hasFiles = false;
      if (product?.images) {
        const filesArray = [];
        for (const image of product?.images) {
          if (image?.src instanceof File) {
            filesArray.push(image?.src);
          }
        }

        filesArray.forEach((file) => {
          uploadImgFormData.append("files", file);
          hasFiles = true;
        });
      }
      if (hasFiles) {
        const response = await uploadFiles(uploadImgFormData);
        return response?.data?.uploadedFilePaths;
      } else {
        return;
      }
    } catch (err) {
      console.error("Failed to upload files", err);
      throw err;
    }
  };
  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoader(true);
      const updatedSpecification = (values?.specification || []).reduce(
        (acc, { key, value }) => {
          if (key && value) {
            acc[key.toLowerCase()] = value;
          }
          return acc;
        },
        {}
      );
      const _product = {
        ...values,
        specification: updatedSpecification,
        images: values?.images,
      };
      const uplodedURLs = await uploadAllImages(values);
      const updatedProduct = {
        ..._product,
        images: _product?.images
          ?.filter((image) => !image.isThumbnail)
          ?.map(({ alt, magnifySrc, isThumbnail, src }) => {
            if (src instanceof File && !isThumbnail) {
              return {
                alt,
                magnifySrc: uplodedURLs[src.name]?.src,
                src: uplodedURLs[src.name]?.src500,
              };
            }
            return { alt, magnifySrc, src };
          }),
        thumbnail:
          _product?.images
            ?.filter((image) => image.isThumbnail)
            ?.map(({ alt, magnifySrc, isThumbnail, src }) => {
              if (src instanceof File && isThumbnail) {
                return {
                  alt,
                  magnifySrc: uplodedURLs[src.name]?.src,
                  src: uplodedURLs[src.name]?.src500,
                };
              }
              return { alt, magnifySrc, src };
            })[0] || null, // Store thumbnail data
      };

      const statusObj = {
        ProductStatus: _product?.productStatus,
        PackagingStatus: _product?.packagingStatus,
        DamageStatus: _product?.damageStatus || null,
        conditionNotes: _product?.conditionNotes || null,
      };
      const productInput = {
        categoryId: _product?.subCategoryId
          ? _product?.subCategoryId
          : _product?.categoryId,
        sku: _product?.sku,
        description: _product?.description,
        displayName: _product?.displayName,
        originalName: _product?.originalName,
        estimatedBid: _product?.estimatedBid,
        specification: _product?.specification,
        vendorName: _product?.vendorName,
        images: updatedProduct?.images,
        thumbnailImage: updatedProduct?.thumbnail,
        status: statusObj,
        filterBy: _product?.filterBy,
      };
      if (isUpdate) {
        try {
          productInput.productId = params?.productId;
          const { data } = await editProduct({
            variables: { productInput },
          });
          if (data.updateProduct) {
            successToaster("Product updated successfully!");
            resetForm();
            getProduct();
            setLoader(false);
            navigate("/products/all-product");
          }
        } catch (error) {
          throw error;
        }
      } else {
        try {
          const { data } = await addProduct({
            variables: { productInput },
          });
          if (data.addProduct) {
            successToaster("Product added successfully!");
            resetForm();
            setLoader(false);
            navigate("/products/all-product");
          }
        } catch (error) {
          throw error;
        }
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
      errorToaster(error.message);
    }
  };
  const handleAddSpecification = (values) => {
    const productSpecificationArr = values.specification || [];
    const newSpecification = [...productSpecificationArr, initialSpecification];
    setProductDetail({ ...values, specification: newSpecification });
  };

  const handleDeleteSpecification = (specificationIndex, values) => {
    const productSpecification = values.specification;
    const newSpecification = productSpecification?.filter(
      (specification, index) => index !== specificationIndex
    );
    setProductDetail({ ...values, specification: newSpecification });
  };
  return (
    <Box className="content-form-detail-contanier">
      <BreadcrumbsComponent breadcrumbs={breadcrumbsArray} />
      <Formik
        initialValues={{
          displayName: productDetail.displayName,
          originalName: productDetail.originalName,
          estimatedBid: productDetail.estimatedBid,
          description: productDetail.description,
          sku: productDetail.sku,
          specification: productDetail.specification,
          categoryId: productDetail.categoryId,
          subCategoryId: productDetail.subCategoryId,
          display: productDetail.display,
          vendorName: productDetail.vendorName,
          images: productDetail?.images,
          productStatus: productDetail.productStatus,
          packagingStatus: productDetail.packagingStatus,
          damageStatus: productDetail.damageStatus,
          conditionNotes: productDetail.conditionNotes,
          filterBy: productDetail?.filterBy,
        }}
        enableReinitialize={true}
        // validationSchema={generateValidationSchema(validationFields)}
        validationSchema={DisplayingErrorMessagesSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, { resetForm });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          resetForm,
          setFieldValue,
        }) => {
          const categoriesData = categories?.getCategories?.categories;
          const handleCategoryChange = (event) => {
            const selectedCategoryId = event.target.value;
            const subCategories = getSubCategories(selectedCategoryId);
            setSubCategoryOptions(subCategories);

            const category = categoriesData?.find(
              (cat) => cat?._id === selectedCategoryId
            );
            const isFilterApplicable = category?.isFilterApplicable;
            if (!isFilterApplicable) {
              setFieldValue("filterBy", null);
            }
            setShowRadio(isFilterApplicable);
            handleChange(event);
          };
          return (
            <Form className="content-form" encType="multipart/form-data">
              <ScrollbarComponant
                sx={{
                  height: {
                    sm: "calc(100% - 25px)",
                  },
                }}
              >
                <Grid
                  container
                  spacing={2}
                  className="content-filter-container"
                >
                  <Grid item xs={12} sm={12} lg={6}>
                    <CardComponent name="Basic Information">
                      <InputComponent
                        type="text"
                        id="originalName"
                        label="Product original Name"
                        name="originalName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.originalName}
                        errors={errors?.originalName}
                        touched={touched?.originalName}
                      />
                      <InputComponent
                        type="text"
                        id="displayName"
                        label="Product display Name"
                        name="displayName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.displayName}
                        errors={errors?.displayName}
                        touched={touched?.displayName}
                      />
                      <InputComponent
                        type="number"
                        id="estimatedBid"
                        label="Estimated Bid"
                        name="estimatedBid"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.estimatedBid}
                        errors={errors?.estimatedBid}
                        touched={touched?.estimatedBid}
                      />
                      <RichTextBoxEditorComponent
                        id="description"
                        label="Description"
                        name="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.description}
                        errors={errors?.description}
                        touched={touched?.description}
                        isUpdate={isUpdate}
                      />
                      <InputComponent
                        type="text"
                        id="vendorName"
                        label="Vendor Name"
                        name="vendorName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.vendorName}
                        errors={errors?.vendorName}
                        touched={touched?.vendorName}
                      />
                      <InputComponent
                        type="text"
                        id="sku"
                        label="SKU"
                        name="sku"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.sku}
                        errors={errors?.sku}
                        touched={touched?.sku}
                      />
                    </CardComponent>

                    <CardComponent name="Category">
                      <br />
                      <Grid container spacing={2}>
                        <Grid item lg={12} sm={12}>
                          <SelectComponent
                            id="categoryId"
                            label="Select Category"
                            name="categoryId"
                            options={categoryOptions}
                            onChange={handleCategoryChange}
                            onBlur={handleBlur}
                            value={values?.categoryId}
                            errors={errors?.categoryId}
                            touched={touched?.categoryId}
                          />
                          {subCategoryOptions.length ? (
                            <SelectComponent
                              id="subCategoryId"
                              label="Select Sub Category"
                              name="subCategoryId"
                              options={subCategoryOptions}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.subCategoryId}
                              errors={errors?.subCategoryId}
                              touched={touched?.subCategoryId}
                            />
                          ) : (
                            <></>
                          )}
                          {showRadio && (
                            <>
                              <FormLabel id="filterBy">Filter</FormLabel>
                              <RadioGroup
                                row
                                onChange={handleChange}
                                value={values?.filterBy}
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="filterBy"
                              >
                                <FormControlLabel
                                  value="FEMALE"
                                  control={<Radio />}
                                  label="Female"
                                />
                                <FormControlLabel
                                  value="MALE"
                                  control={<Radio />}
                                  label="Male"
                                />
                                <FormControlLabel
                                  value="UNISEX"
                                  control={<Radio />}
                                  label="Unisex"
                                />
                              </RadioGroup>
                              {errors?.filterBy && touched?.filterBy && (
                                <Box className="input-error">
                                  {errors?.filterBy}
                                </Box>
                              )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </CardComponent>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={6}>
                    <CardComponent name="Product Image">
                      <Grid item xs={12} className="variantdata-icon">
                        <Box>
                          <FileInputComponent
                            type="file"
                            label="Image"
                            id="images"
                            name="images"
                            ref={thumbnailImageRef}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isMultifile={true}
                            value={values}
                            errors={errors?.variantData?.options?.logo}
                            err={errors.images}
                            touched={touched?.variantData?.options?.logo}
                            accept=".jpg, .jpeg, .png, .webp,"
                            isUpdate={isUpdate}
                            setProductDetail={(data) => {
                              handleChange({
                                target: { name: "images", value: data },
                              });
                            }}
                          />
                        </Box>
                      </Grid>
                    </CardComponent>
                    <CardComponent name="Status">
                      <SelectComponent
                        id="productStatus"
                        label="Select Product Status"
                        name="productStatus"
                        options={productStatusOptions}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.productStatus}
                        errors={errors?.productStatus}
                        touched={touched?.productStatus}
                      />
                      <SelectComponent
                        id="packagingStatus"
                        label="Select Packaging Status"
                        name="packagingStatus"
                        options={packagingStatusOption}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.packagingStatus}
                        errors={errors?.packagingStatus}
                        touched={touched?.packagingStatus}
                      />
                      <SelectComponent
                        id="damageStatus"
                        label="Select Damage Status"
                        name="damageStatus"
                        options={damageStatusOption}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.damageStatus}
                        errors={errors?.damageStatus}
                        touched={touched?.damageStatus}
                      />
                      <InputComponent
                        type="text"
                        id="conditionNotes"
                        label="Condition Note"
                        name="conditionNotes"
                        multiline={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.conditionNotes}
                        errors={errors?.conditionNotes}
                        touched={touched?.conditionNotes}
                      />
                    </CardComponent>
                    <CardComponent name="Specification">
                      {values?.specification?.map((specification, index) => {
                        return (
                          <Box className="specification-detail-contanier">
                            <InputComponent
                              type="text"
                              id={`specification-${index}-key`}
                              label="Key"
                              name={`specification[${index}].key`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={specification?.key}
                              errors={errors?.specification?.[index]?.key}
                              touched={touched?.specification?.[index]?.key}
                              width="50%"
                            />
                            <InputComponent
                              type="text"
                              id={`specification-${index}-value`}
                              label="Value"
                              name={`specification[${index}].value`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={specification?.value}
                              errors={errors?.specification?.[index]?.value}
                              touched={touched?.specification?.[index]?.value}
                              width="50%"
                            />

                            <span
                              className="specification-delete-icon"
                              onClick={() =>
                                handleDeleteSpecification(index, values)
                              }
                            >
                              <MdDelete />
                            </span>
                          </Box>
                        );
                      })}
                      <Box className="add-size-icon-contanier">
                        <Button onClick={() => handleAddSpecification(values)}>
                          <MdAddBox className="add-size-icon" />
                          <span>Add more spacification</span>
                        </Button>
                      </Box>
                    </CardComponent>
                  </Grid>
                </Grid>
              </ScrollbarComponant>
              <Box className="button-contanier">
                <ButtonComponent
                  label={isUpdate ? "Update" : "Submit"}
                  type="submit"
                  isLoader={loader}
                />
                <ButtonComponent
                  label="Cancel"
                  type="button"
                  onClick={() => {
                    resetForm();
                    navigate(Route.ALLPRODUCT);
                  }}
                  className="cancel-btn"
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AddorEditProduct;
