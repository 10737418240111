import React from "react";
import { Routes } from "../enums/Routes.enum";

/* Authentication Components */
import Login from "../pages/Authentication/Login";
import Signup from "../pages/Authentication/Signup";

export const publicRoutes = [
  {
    path: Routes.LOGIN,
    cmp: <Login />
  },
  {
    path: Routes.SIGNUP,
    cmp: <Signup />
  }
];
