import { gql } from "@apollo/client";

const LOGIN_MUTATION = gql`
  mutation LoginEmployee($email: String!, $password: String!) {
    loginEmployee(email: $email, password: $password) {
      token
      refreshToken
      user {
        _id
        firstName
        lastName
        email
        role
      }
    }
  }
`;
export default LOGIN_MUTATION;
