import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

/* Layout */
import Layout from "./Layout";

/* Routes */
import { privateRoutes, publicRoutes } from "./routes";

import "./index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/* Routes */
const routesArray = [...privateRoutes, ...publicRoutes];
function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {routesArray.map((route, index) => (
            <Route key={index} path={route.path} element={route.cmp} />
          ))}
          {routesArray.map(
            (route) =>
              route.subRoutes &&
              route.subRoutes.map((subRoute, subIndex) => (
                <Route
                  key={subIndex}
                  path={subRoute.path}
                  element={subRoute.cmp}
                />
              ))
          )}
        </Routes>
      </Layout>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
