import { gql } from "@apollo/client";

export const GET_SELLER_INVOICES = gql`
  query GetSellerInvoices($invoicesInput: GetInvoicesInput) {
    getSellerInvoices(InvoicesInput: $invoicesInput) {
      totalPages
      total
      currentPage
      currentLimit
      invoices {
        _id
        invoiceNumber
        seller {
          _id
          companyName
          firstName
          lastName
          email
          gstNumber
          mobileNumber
          businessNumber
          registrationNumber
        }
        products {
          productId
          name
          sellingPrice
          thumbnailImage {
            src
            alt
            magnifySrc
          }
          customer {
            _id
            userName
            firstName
            lastName
            email
            gstNumber
            faceBookUrl
            instagramUrl
          }
          sku
        }
        type
        subTotal
        HST
        GST
        PL_Charges
        EX_Charges
        shippingFee
        total
        shippingType
        dueDate
        paidDate
        pickupDate
        auctionDate
        warrantyDeadline
        createdAt
        updatedAt
      }
    }
  }
`;
