export const RouteNames = {
  LOGIN: "login",
  SIGNUP: "signup",
  DASHBOARD: "dashboard",
  EMPLOYEE: "employee",
  CATEGORY: "category",
  PRODUCTS: "products",
  ALLPRODUCT: "all product",
  UNDERWARRANTYPRODUCT: "under warranty product",
  SOLDPRODUCT: "sold product",
  INVOICE: "invoice",
};

export const Routes = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  DASHBOARD: "/dashboard",
  EMPLOYEE: "/employee",
  ADD_EMPLOYEE: "/employee/create",
  EDIT_EMPLOYEE: "/employee/edit/:employeeId",
  CATEGORY: "/category",
  PRODUCTS: "/products",
  ADDPRODUCT: "/products/create",
  EDITPRODUCT: "/products/edit/:productId",
  ALLPRODUCT: "/products/all-product",
  UNDERWARRANTYPRODUCT: "/products/under-warranty-product",
  SOLDPRODUCT: "/products/sold-product",
  VIEWPRODUCT: "/products/all-product/view-product/:productId",
  INVOICE: "/invoice",
  INVOICEDETAILS: "/invoice/details/:invoiceId",
};
