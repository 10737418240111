export const OrderStatus = {
  ORDER_PLACED: "ORDER_PLACED",
  ON_THE_WAY: "ON_THE_WAY",
  DELIVERED: "DELIVERED",
  DISPUTE: "DISPUTE",
  REFUND: "REFUNDED",
};

export const statusColors = {
  ORDER_PLACED: "#0000FF",
  ON_THE_WAY: "#FFA500",
  DELIVERED: "#008000",
  DISPUTE: "#FF0000",
  REFUNDED: "#FF0000",
};

export const productStatus = {
  NewItem: "New Item",
  OpenAppearNew: "Open ( Appear New )",
  OpenContentsBrandNew: "Open ( Contents Brand New )",
  LightlyUsedOrWorn: "Lightly Used or Worn",
  HandledUsed: "Handled / Used",
  AsIsExpired: "As-is (Expired)",
};

export const PackagingStatus = {
  BrandSealed: "Brand Sealed",
  RetailPackagingDistressed: "Retail Packaging (Distressed)",
  SealedButDistressedPackaging: "Sealed But Distressed Packaging",
  NoPackagingOrRepackaged: "No packaging or repackaged",
};

export const damageStatus = {
  withIssue: "With issue (Red Warning mark)",
  cosmeticImperfection: "Cosmetic imperfection (Yellow Warning mark)",
};
