import { GET_SELLER_INVOICES } from "./query";
import { useQuery } from "@apollo/client";
import { React, useEffect, useMemo, useRef, useState } from "react";
import TableComponent from "../../components/TableComponent";
import { Box, Grid } from "@mui/material";
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent";
import SearchComponent from "../../components/SearchComponent";
import { errorToaster } from "../../components/Toaster";
import { useNavigate } from "react-router-dom";
import InvoiceComponent from "../../components/invoiceComponent";
import generatePDF from "react-to-pdf";
import moment from "moment";

const Invoice = () => {
  const breadcrumbsArray = [{ title: "Invoice" }];
  const invoiceRef = useRef(null);
  const navigate = useNavigate();
  const [invoicesData, setInvoicesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [invoiceDataObj, setInvoiceDataObj] = useState(null);
  const [showInvoice, setShowInvoice] = useState(true);
  const [loader, setLoader] = useState(false);
  const pageSize = 10;

  const {
    data,
    refetch: getSellerInvoices,
    loading,
    error,
  } = useQuery(GET_SELLER_INVOICES);

  if (error) {
    errorToaster(error);
  }

  useEffect(() => {
    if (data) {
      const invoices = data?.getSellerInvoices?.invoices || [];
      const formattedInvoices = invoices.map((invoice) => ({
        ...invoice,
        invoiceNumber: invoice.invoiceNumber ?? "-",
        auctionDate: moment(invoice.auctionDate).format(
          "MMMM D, YYYY, hh:mm A"
        ),
        warrantyDeadline: moment(invoice.warrantyDeadline).format(
          "MMMM D, YYYY, hh:mm A"
        ),
        total: invoice.total ?? "-",
        totalProducts: invoice.products?.length ?? "-",
        paymentStatus: invoice.paymentStatus ?? "-",
        shippingType: invoice.shippingType ?? "-",
      }));
      setInvoicesData(formattedInvoices);
    }
  }, [data]);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  useEffect(() => {
    getSellerInvoices({
      invoicesInput: { page: currentPage, limit: pageSize },
    });
  }, [getSellerInvoices, currentPage]);

  const handleSearch = (value) => {
    getSellerInvoices({
      invoicesInput: { search: value },
    });
  };

  const handleViewInvoice = (invoiceId) => {
    navigate(`/invoice/details/${invoiceId}`);
  };

  const handleViewInvoicePdf = async (invoiceData) => {
    setInvoiceDataObj(invoiceData);
    setTimeout(async () => {
      setShowInvoice(false);
      const content = () => {
        let element = invoiceRef.current;
        if (element) {
          element.style.display = "block";
        }
        return element;
      };

      const options = {
        filename: `invoice-${invoiceData?.invoiceNumber || "invoice"}.pdf`,
        page: {
          margin: 20,
        },
      };

      try {
        await generatePDF(content, options);
      } catch (error) {
        console.error("Error generating PDF:", error);
      } finally {
        setShowInvoice(true);
      }
    }, 100);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "invoiceNumber",
        header: "Invoice Number",
      },
      {
        accessorKey: "auctionDate",
        header: "Auction Date",
      },
      {
        accessorKey: "warrantyDeadline",
        header: "Warranty Deadline",
      },
      {
        accessorKey: "total",
        header: "Total Amount",
      },
      {
        accessorKey: "products.length",
        header: "Total Product",
      },
      {
        accessorKey: "paymentStatus",
        header: "Payment Status",
      },
      {
        accessorKey: "shippingType",
        header: "Shipping Type",
      },
    ],
    []
  );

  return (
    <>
      <Box className="content-contanier">
        <BreadcrumbsComponent breadcrumbs={breadcrumbsArray} />
        <Grid container spacing={2} className="content-filter-container">
          <Grid item xs={6}>
            <SearchComponent handleSearch={handleSearch} />
          </Grid>
        </Grid>

        <TableComponent
          columns={columns}
          data={invoicesData}
          hasCopyBtn={true}
          isManualPagination={true}
          handlePageChange={handlePageChange}
          rowCount={data?.getEmployee?.total || 0}
          density="compact"
          loader={loader}
          isInvoiceView={true}
          handleViewInvoice={handleViewInvoice}
          isInvoiceViewIcon={true}
          enableActions={true}
          handleViewInvoicePdf={handleViewInvoicePdf}
        />
      </Box>

      <div>
        {showInvoice && (
          <InvoiceComponent invoiceDetails={invoiceDataObj} ref={invoiceRef} />
        )}
      </div>
    </>
  );
};

export default Invoice;
