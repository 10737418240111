import { gql } from "@apollo/client";

export const SIGNUP_MUTATION = gql`
  mutation SignUpSeller($sellerInput: SellerInput) {
    signUpSeller(SellerInput: $sellerInput) {
      _id
      companyName
      firstName
      lastName
      email
      mobileNumber
      businessNumber
      registrationNumber
    }
  }
`;
