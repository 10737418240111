import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadcrumbsComponent from "../../../components/BreadcrumbsComponent";
import { useQuery } from "@apollo/client";
import { GETPRODUCTS_QUERY } from "../query";
import { useParams } from "react-router-dom";
import { GET_CATEGORIES_QUERY } from "../../Category/query";
import { loadFile } from "../../../utils/loadFile";

const ViewProduct = () => {
  const { productId } = useParams();
  const [productData, setProductData] = useState(null);
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);

  const breadcrumbsArray = [
    { title: "Product", link: "/products/all-product" },
    { title: "View Product" },
  ];

  const { data, loading, error } = useQuery(GETPRODUCTS_QUERY, {
    variables: {
      productInput: { id: productId },
    },
  });

  const { data: categoryData } = useQuery(GET_CATEGORIES_QUERY, {
    variables: {
      categoryInput: { id: data?.getProducts?.products[0]?.category?.parentId },
    },
  });

  useEffect(() => {
    if (data) {
      const product = data.getProducts.products[0];
      setProductData(product);
      setSelectedThumbnail(product.thumbnailImage?.src);
    }
  }, [data]);

  if (loading) return <Typography variant="h6">Loading...</Typography>;
  if (error)
    return <Typography variant="h6">Error: {error.message}</Typography>;

  const handleThumbnailClick = (src) => {
    setSelectedThumbnail(src);
  };

  return (
    <Box className="content-container">
      <BreadcrumbsComponent breadcrumbs={breadcrumbsArray} />
      <Box p={2} mt={3} boxShadow={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ mb: 2, color: "#c1761f" }}
              >
                Product Details
              </Typography>
              <Box pl={2}>
                <Typography variant="body1" sx={{ mb: 1, fontSize: "1.1rem" }}>
                  <strong>Original Name:</strong> {productData?.originalName}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1, fontSize: "1.1rem" }}>
                  <strong>Display Name:</strong> {productData?.displayName}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 1,
                    fontSize: "1.1rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <strong>Description:</strong>
                  {productData?.description.replace(/<[^>]+>/g, "") || "-"}
                </Typography>

                <Typography variant="body1" sx={{ mb: 1, fontSize: "1.1rem" }}>
                  <strong>Category Name:</strong>
                  {categoryData?.getCategories?.categories[0]?.name || "-"}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1, fontSize: "1.1rem" }}>
                  <strong>Subcategory Name:</strong>
                  {productData?.category?.name}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1, fontSize: "1.1rem" }}>
                  <strong>Vendor Name:</strong> {productData?.vendorName}
                </Typography>

                <Typography variant="body1" sx={{ mb: 1, fontSize: "1.1rem" }}>
                  <strong>SKU:</strong> {productData?.sku || "-"}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1, fontSize: "1.1rem" }}>
                  <strong>Current Bid:</strong>
                  {productData?.currentBid ? `$${productData.currentBid}` : "-"}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1, fontSize: "1.1rem" }}>
                  <strong>Estimated Bid:</strong>
                  {productData?.estimatedBid
                    ? `$${productData.estimatedBid}`
                    : "-"}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1, fontSize: "1.1rem" }}>
                  <strong>Auction Date:</strong>
                  {productData?.auctionDate
                    ? new Date(productData.auctionDate).toLocaleString()
                    : "-"}
                </Typography>
              </Box>

              <Box mt={3}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{ mb: 1, color: "#c1761f" }}
                >
                  Specifications
                </Typography>
                <Box pl={2}>
                  {Object.entries(productData?.specification || {}).map(
                    ([key, value]) => (
                      <Typography
                        key={key}
                        variant="body1"
                        sx={{ mb: 1, fontSize: "1.1rem" }}
                      >
                        <strong>{key}:</strong> {value}
                      </Typography>
                    )
                  )}
                </Box>
              </Box>

              <Box mt={3}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{ mb: 1, color: "#c1761f" }}
                >
                  Status
                </Typography>
                <Box pl={2}>
                  <Typography
                    variant="body1"
                    sx={{ mb: 1, fontSize: "1.1rem" }}
                  >
                    <strong>Product Status:</strong>
                    {productData?.status?.ProductStatus || "-"}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mb: 1, fontSize: "1.1rem" }}
                  >
                    <strong>Packaging Status:</strong>
                    {productData?.status?.PackagingStatus || "-"}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mb: 1, fontSize: "1.1rem" }}
                  >
                    <strong>Damage Status:</strong>
                    {productData?.DamageStatus || "-"}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mb: 1, fontSize: "1.1rem" }}
                  >
                    <strong>Condition Notes:</strong>
                    {productData?.conditionNotes || "-"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                {selectedThumbnail && (
                  <img
                    src={loadFile(selectedThumbnail)}
                    alt={productData?.originalName}
                    style={{
                      width: "400px",
                      maxHeight: "250px",
                      objectFit: "contain",
                      marginBottom: "0px",
                    }}
                  />
                )}

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  style={{ marginTop: "10px" }}
                >
                  {productData?.thumbnailImage?.src && (
                    <Box style={{ display: "flex" }}>
                      <img
                        src={loadFile(productData.thumbnailImage.src)}
                        alt="Thumbnail"
                        style={{
                          width: "75px",
                          height: "auto",
                          maxHeight: "50px",
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleThumbnailClick(productData.thumbnailImage.src)
                        }
                      />
                    </Box>
                  )}

                  {productData?.images.length > 0 &&
                    productData.images.map((image, index) => (
                      <Box key={index} style={{ display: "flex" }}>
                        <img
                          src={loadFile(image.src)}
                          alt={image.alt || `Product image ${index + 1}`}
                          style={{
                            width: "75px",
                            height: "auto",
                            maxHeight: "50px",
                            objectFit: "contain",
                            cursor: "pointer",
                          }}
                          onClick={() => handleThumbnailClick(image.src)}
                        />
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ViewProduct;
