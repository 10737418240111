import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import "./SelectComponent.css";

const SelectComponent = ({
  label,
  value,
  onChange,
  id,
  name,
  options,
  onBlur,
  errors,
  touched,
}) => {
  return (
    <Box className="select-input-contanier">
      <FormControl fullWidth>
        <InputLabel
          id={id}
          sx={{ lineHeight: "unset", fontSize: "14px", top: "-5px" }}
        >
          {label}
        </InputLabel>
        <Select
          labelId={id}
          id={id}
          name={name}
          value={value}
          label={label}
          onChange={onChange}
          onBlur={onBlur}
          sx={{
            borderRadius: "8px",
            "& .MuiSelect-select": {
              padding: "10px 12px",
              fontSize: "14px",
            },
          }}
        >
          {options?.length > 0 &&
            options.map((option, index) => {
              return (
                <MenuItem
                  key={index}
                  value={option.value}
                  className="menu-item"
                >
                  {option.title}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      {errors && touched && <Box className="input-error">{errors}</Box>}
    </Box>
  );
};

export default SelectComponent;
