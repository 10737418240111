import React from "react";
import { useMutation } from "@apollo/client";
import { Box, Divider, Link } from "@mui/material";
import InputComponent from "../../../components/InputComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import signupImage from "../../../assets/Signup/signup.svg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { setLocalStorageItem } from "../../../utils/localStorage";
import { useNavigate } from "react-router-dom";
import { errorToaster, successToaster } from "../../../components/Toaster";
import { SIGNUP_MUTATION } from "./query";
import PhoneComponent from "../../../components/PhoneComponent";
import "./Signup.css";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  companyName: Yup.string().required("Company Name is required."),
  firstName: Yup.string().required("First Name is required."),
  lastName: Yup.string().required("Last Name is required."),
  email: Yup.string()
    .email("Enter a valid email.")
    .required("Email is required."),
  mobileNumber: Yup.string()
    .matches(
      /^(\+1)?[2-9]\d{2}[2-9]\d{2}\d{4}$/,
      "Mobile Number must be a valid Canadian number."
    )
    .required("Mobile Number is required."),
  businessNumber: Yup.number()
    .typeError("Business Number must be a number.")
    .required("Business Number is required."),
  registrationNumber: Yup.number()
    .typeError("Registration Number must be a number.")
    .required("Registration Number is required."),
  password: Yup.string().required("Password is required."),
});

const Signup = () => {
  const navigate = useNavigate();
  const [signUpSeller] = useMutation(SIGNUP_MUTATION);

  const handleSubmit = async (values, { resetForm }) => {
    const sellerInput = {
      businessNumber: String(values.businessNumber),
      companyName: values.companyName,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      mobileNumber: values.mobileNumber,
      password: values.password,
      registrationNumber: String(values.registrationNumber),
    };
    try {
      const { data } = await signUpSeller({
        variables: { sellerInput },
      });
      if (data?.signUpSeller) {
        setLocalStorageItem("signUpSeller", JSON.stringify(data?.signUpSeller));
        successToaster("Signup Successfully!");
        resetForm();
        navigate("/login");
      }
    } catch (error) {
      errorToaster(error);
      console.error(error);
    }
  };

  return (
    <Box className="signup-container">
      <Box className="signup-form-contanier">
        <Box className="form-contanier">
          <Box className="logo">
            <h2>Auction Admin</h2>
          </Box>
          <Box className="form-section">
            <Box className="signup-form">
              <Box className="title-contanier">
                <h2>Sign up</h2>
                <span>Enter your credentials to continue</span>
              </Box>
              <Formik
                initialValues={{
                  companyName: "",
                  firstName: "",
                  lastName: "",
                  email: "",
                  mobileNumber: "",
                  businessNumber: "",
                  registrationNumber: "",
                  password: "",
                }}
                validationSchema={DisplayingErrorMessagesSchema}
                onSubmit={(values, { resetForm }) => {
                  handleSubmit(values, { resetForm });
                }}
              >
                {({ values, errors, touched, handleChange, handleBlur }) => (
                  <Form autoComplete="off">
                    <InputComponent
                      type="text"
                      id="companyName"
                      label="company Name"
                      name="companyName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.companyName}
                      errors={errors?.companyName}
                      touched={touched?.companyName}
                    />
                    <InputComponent
                      type="text"
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.firstName}
                      errors={errors?.firstName}
                      touched={touched?.firstName}
                    />
                    <InputComponent
                      type="text"
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.lastName}
                      errors={errors?.lastName}
                      touched={touched?.lastName}
                    />
                    <InputComponent
                      type="text"
                      id="email"
                      label="Email Address"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.email}
                      errors={errors?.email}
                      touched={touched?.email}
                    />
                    <PhoneComponent
                      type="tel"
                      label="mobile Number"
                      name="mobileNumber"
                      value={values.mobileNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.mobileNumber}
                      touched={touched.mobileNumber}
                      maxLength={10}
                      pattern="\d{10}"
                    />
                    <InputComponent
                      type="number"
                      id="businessNumber"
                      name="businessNumber"
                      label="Business Number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.businessNumber}
                      errors={errors?.businessNumber}
                      touched={touched?.businessNumber}
                    />
                    <InputComponent
                      type="number"
                      id="registrationNumber"
                      name="registrationNumber"
                      label="Registration Number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.registrationNumber}
                      errors={errors?.registrationNumber}
                      touched={touched?.registrationNumber}
                    />
                    <InputComponent
                      type="password"
                      id="password"
                      label="Password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.password}
                      errors={errors?.password}
                      touched={touched?.password}
                    />
                    <Box className="button-contanier">
                      <ButtonComponent
                        label="Sign Up"
                        type="submit"
                        width="100%"
                        className="signup-btn "
                      />
                    </Box>
                  </Form>
                )}
              </Formik>
              <Divider className="divider" />
              <Box className="signup-footer-container">
                <Link
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Already have an account?
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="image-contanier">
        <img src={signupImage}  alt={"SignUp-Image"}/>
      </Box>
    </Box>
  );
};

export default Signup;
