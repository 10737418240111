import React, { useState } from "react";

import { useMutation } from "@apollo/client";
import LOGIN_MUTATION from "./query";
import { Box, Divider, Link } from "@mui/material";
import "./Login.css";
import InputComponent from "../../../components/InputComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import loginImage from "../../../assets/Login/login.svg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { setLocalStorageItem } from "../../../utils/localStorage";
import { useNavigate } from "react-router-dom";
import { errorToaster, successToaster } from "../../../components/Toaster";
import { getSecretAccess } from "../../../api/FileUploadAPI";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  email: Yup.string()
    .email("Enter a valid email.")
    .required("Email is required."),
  password: Yup.string().required("Password is required."),
});

const Login = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [loginEmployee] = useMutation(LOGIN_MUTATION);

  const handleSubmit = async (values) => {
    try {
      setLoader(true);
      const email = values.email;
      const password = values.password;
      const { data } = await loginEmployee({ variables: { email, password } });
      const { refreshToken, token, user } = data?.loginEmployee;
      if (data?.loginEmployee) {
        try {
          // const response = await getSecretAccess({
          //   email: "shubhamkanani.dds@gmail.com",
          //   password: "Admin@123"
          // });
          const response = await getSecretAccess({
            email: "dev-support@theauctionhub.ca",
            password: "dev-auctionhub@123",
          });
          if (response.data.accessKeyId && response.data.secretAccessKey) {
            setLocalStorageItem("accessKeyId", response.data.accessKeyId);
            setLocalStorageItem(
              "secretAccessKey",
              response.data.secretAccessKey
            );
          }
        } catch (e) {
          console.error(
            "error at getting secret access key for image upload: ",
            e
          );
        }
        setLocalStorageItem("accessToken", token);
        setLocalStorageItem("refreshToken", refreshToken);
        setLocalStorageItem("user", JSON.stringify(user));
        successToaster("Login Successfully!");
        setLoader(false);
        navigate("/dashboard");
      }
    } catch (error) {
      setLoader(false);
      errorToaster(error.message);
    }
  };

  return (
    <Box className="login-container">
      <Box className="login-form-contanier">
        <Box className="form-contanier">
          <Box className="logo">
            <h2>Ecommerce</h2>
          </Box>
          <Box className="form-section">
            <Box className="login-form">
              <Box className="title-contanier">
                <h2>Hi, Welcome Back</h2>
                <span>Enter your credentials to continue</span>
              </Box>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={DisplayingErrorMessagesSchema}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {({ values, errors, touched, handleChange, handleBlur }) => (
                  <Form autoComplete="off">
                    <InputComponent
                      type="text"
                      id="email"
                      label="Email Address"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.email}
                      errors={errors?.email}
                      touched={touched?.email}
                    />
                    <InputComponent
                      type="password"
                      id="password"
                      label="Password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.password}
                      errors={errors?.password}
                      touched={touched?.password}
                    />
                    <Box className="button-contanier">
                      <ButtonComponent
                        label="Sign In"
                        type="submit"
                        width="100%"
                        className="login-btn"
                        isLoader={loader}
                      />
                    </Box>
                  </Form>
                )}
              </Formik>
              <Divider className="divider" />
              <Box className="login-footer-container">
                <Link
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  Don't have an account?
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="image-contanier">
        <img src={loginImage} alt={"Login-Image"} />
      </Box>
    </Box>
  );
};

export default Login;
