import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadcrumbsComponent from "../../../components/BreadcrumbsComponent";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import ScrollbarComponant from "../../../components/ScrollbarComponant";
import { CardComponent } from "../../../components/CardComponent";
import InputComponent from "../../../components/InputComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import PhoneComponent from "../../../components/PhoneComponent";
import { errorToaster, successToaster } from "../../../components/Toaster";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_EMPLOYEE, GET_EMPLOYEE, UPDATE_EMPLOYEE } from "../query";

const AddorEditEmployee = () => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [loader, setLoader] = useState(false);
  const [CreateEmployee] = useMutation(CREATE_EMPLOYEE);
  const [UpdateEmployee] = useMutation(UPDATE_EMPLOYEE);
  const initialEmployeeDetail = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobileNumber: ""
  };

  const { employeeId } = useParams();
  const { data, refetch } = useQuery(GET_EMPLOYEE, {
    variables: {
      getEmployeeInput: { id: employeeId }
    }
  });
  const [employeeDetail, setEmployeeDetail] = useState(initialEmployeeDetail);
  const navigate = useNavigate();
  const breadcrumbsArray = [
    { title: "Employee", link: "/employee" },
    { title: `${isUpdate ? "Edit Employee" : "Add Employee"}` }
  ];

  const DisplayingErrorMessagesSchema = (isUpdate) => {
    return Yup.object().shape({
      firstName: Yup.string()
        .required("First Name is required")
        .min(2, "First Name must be at least 2 characters"),
      lastName: Yup.string()
        .required("Last Name is required")
        .min(2, "Last Name must be at least 2 characters"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      mobileNumber: Yup.string()
        .matches(/^[0-9]{10}$/, "Mobile Number must be exactly 10 digits")
        .required("Mobile Number is required"),
      ...(isUpdate
        ? {}
        : {
            password: Yup.string()
              .required("Password is required")
              .min(8, "Password must be at least 8 characters")
          })
    });
  };

  useEffect(() => {
    if (data && employeeId) {
      setIsUpdate(true);
      setEmployeeDetail(data?.getEmployee?.employees?.[0]);
    }
  }, [data, employeeId]);

  const handleSubmit = async (values, { resetForm }) => {
    const employeeInput = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      password: values?.password,
      mobileNumber: values?.mobileNumber
    };
    setLoader(true);
    try {
      if (isUpdate) {
        employeeInput.employeeId = employeeId;
        const { data } = await UpdateEmployee({
          variables: { employeeInput }
        });
        if (data?.updateEmployee) {
          successToaster("Employee updated successfully!");
          await refetch();
        }
      } else {
        const { data } = await CreateEmployee({
          variables: { employeeInput }
        });
        if (data?.createEmployee) {
          successToaster("Employee created successfully!");
          await refetch();
        }
      }
      resetForm();
      navigate("/employee");
    } catch (error) {
      errorToaster(error.message);
    } finally {
      setLoader(false);
      setEmployeeDetail(initialEmployeeDetail);
    }
  };

  return (
    <Box className="content-form-detail-contanier">
      <BreadcrumbsComponent breadcrumbs={breadcrumbsArray} />
      <Formik
        initialValues={{
          firstName: employeeDetail?.firstName,
          lastName: employeeDetail?.lastName,
          email: employeeDetail?.email,
          password: employeeDetail?.password,
          mobileNumber: employeeDetail?.mobileNumber
        }}
        enableReinitialize={true}
        validationSchema={DisplayingErrorMessagesSchema(isUpdate)}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, { resetForm });
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, resetForm }) => (
          <Form className="content-form" encType="multipart/form-data">
            <ScrollbarComponant
              sx={{
                height: {
                  sm: "calc(100% - 25px)"
                }
              }}
            >
              <Grid container spacing={2} className="content-filter-container">
                <Grid item xs={6}>
                  <CardComponent name="Basic Information">
                    <InputComponent
                      type="text"
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.firstName}
                      errors={errors?.firstName}
                      touched={touched?.firstName}
                    />
                    <InputComponent
                      type="text"
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.lastName}
                      errors={errors?.lastName}
                      touched={touched?.lastName}
                    />
                    <InputComponent
                      type="text"
                      id="email"
                      label="Email Address"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.email}
                      errors={errors?.email}
                      touched={touched?.email}
                    />
                    {!isUpdate && (
                      <InputComponent
                        type="password"
                        id="password"
                        label="Password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.password}
                        errors={errors?.password}
                        touched={touched?.password}
                      />
                    )}
                    <PhoneComponent
                      type="tel"
                      label="Mobile Number"
                      name="mobileNumber"
                      value={values.mobileNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.mobileNumber}
                      touched={touched.mobileNumber}
                      maxLength={10}
                      pattern="\d{10}"
                    />
                  </CardComponent>
                </Grid>
              </Grid>
            </ScrollbarComponant>
            <Box className="button-contanier">
              <ButtonComponent
                label={isUpdate ? "Update" : "Submit"}
                type="submit"
                isLoader={loader}
              />
              <ButtonComponent
                label="Cancel"
                type="button"
                onClick={() => {
                  resetForm();
                  navigate("/employee");
                }}
                className="cancel-btn"
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddorEditEmployee;
