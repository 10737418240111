import { Box, Button, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import "./SearchComponent.css";
import SearchIcon from "@mui/icons-material/Search";

const SearchComponent = ({ handleSearch }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch(searchQuery.trim());
  };

  return (
    <Box className="search-contanier">
      <form onSubmit={handleSubmit}>
        <TextField
          id="search-bar"
          className="input-search"
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search..."
          size="small"
          InputProps={{
            style: {
              fontSize: "14px",
              backgroundColor: "rgb(248, 250, 252)",
              borderRadius: "8px",
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ fontSize: "20px" }} />
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "var(--bg-button-primary)",
              },
            },
          }}
        />
        <Button type="submit" className="input-search-btn" variant="contained">
          Search
        </Button>
      </form>
    </Box>
  );
};

export default SearchComponent;
