import { useMutation, useQuery } from "@apollo/client";
import { Box, Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent";
import ButtonComponent from "../../components/ButtonComponent";
import DeleteModalComponent from "../../components/DeleteModalComponent";
import SearchComponent from "../../components/SearchComponent";
import TableComponent from "../../components/TableComponent";
import { errorToaster, successToaster } from "../../components/Toaster";
import { Routes } from "../../enums/Routes.enum";
import { DELETE_EMPLOYEE, GET_EMPLOYEE } from "./query";

const Employee = () => {
  const breadcrumbsArray = [{ title: "Employee" }];
  const [employees, setEmployees] = useState([]);
  const [loader, setLoader] = useState(false);
  const [deleteEmployee] = useMutation(DELETE_EMPLOYEE);
  const [isDelete, setIsDelete] = useState({ visible: false, record: null });
  const [isClearRowSelection, setIsClearRowSelection] = useState(false);
  const [isDeleteSelected, setIsDeleteSelected] = useState({
    visible: false,
    record: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const navigate = useNavigate();
  const {
    loading,
    error,
    data,
    refetch: getAllEmployee,
  } = useQuery(GET_EMPLOYEE, {
    variables: {
      getEmployeeInput: {
        limit: pageSize,
        page: 1,
      },
    },
  });

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  useEffect(() => {
    if (data) {
      setEmployees(data?.getEmployee?.employees || []);
    }
  }, [data]);

  useEffect(() => {
    getAllEmployee({
      getEmployeeInput: { page: currentPage, limit: pageSize },
    });
  }, [getAllEmployee, currentPage]);

  const handleSearch = (value) => {
    getAllEmployee({
      getEmployeeInput: { search: value },
    });
  };

  if (error) {
    errorToaster(error.message);
  }

  const handleAddEmployee = () => {
    navigate(Routes.ADD_EMPLOYEE);
  };

  const handleEditEmployee = async (employeeId) => {
    navigate(`/employee/edit/${employeeId}`);
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        header: "Name",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
    ],
    []
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDeleteEmployee = async (empId, isSelected = false) => {
    setLoader(true);
    try {
      const { data } = await deleteEmployee({
        variables: { deleteEmployeeId: empId },
      });
      if (data?.deleteEmployee) {
        await getAllEmployee({
          getEmployeeInput: { page: currentPage, limit: pageSize },
        });
        if (!isSelected) successToaster("Employee deleted successfully");
      }
    } catch (error) {
      errorToaster(error.message);
    } finally {
      setLoader(false);
    }
  };

  const handleDeleteSelectedEmployee = async (empIds) => {
    if (empIds?.length > 0) {
      setLoader(true);
      await Promise.all(
        empIds.map((empId) => handleDeleteEmployee(empId, true))
      );
      await getAllEmployee({
        getEmployeeInput: { page: currentPage, limit: pageSize },
      });
      setIsClearRowSelection(true);
      successToaster("Selected employees deleted successfully");
    }
  };

  return (
    <Box className="content-contanier">
      <BreadcrumbsComponent breadcrumbs={breadcrumbsArray} />
      <DeleteModalComponent
        isDelete={isDelete}
        setIsDelete={setIsDelete}
        deleteRecord={handleDeleteEmployee}
      />
      <DeleteModalComponent
        isDelete={isDeleteSelected}
        setIsDelete={setIsDeleteSelected}
        deleteRecord={handleDeleteSelectedEmployee}
      />
      <Grid container spacing={2} className="content-filter-container">
        <Grid item xs={6}>
          <SearchComponent handleSearch={handleSearch} />
        </Grid>
        <Grid item xs={6} className="filter-section">
          <ButtonComponent label="Add Employee" onClick={handleAddEmployee} />
        </Grid>
      </Grid>

      <TableComponent
        columns={columns}
        data={employees}
        hasCopyBtn={true}
        handleEdit={handleEditEmployee}
        setIsDelete={setIsDelete}
        setIsDeleteSelected={setIsDeleteSelected}
        isManualPagination={true}
        handlePageChange={handlePageChange}
        rowCount={data?.getEmployee?.total || 0}
        density="compact"
        isClearRowSelection={isClearRowSelection}
        loader={loader}
        enableActions={true}
      />
    </Box>
  );
};

export default Employee;
