import { gql } from "@apollo/client";

export const GETPRODUCTS_QUERY = gql`
  query GetProducts($productInput: GetProductInput) {
    getProducts(ProductInput: $productInput) {
      totalPages
      total
      currentPage
      currentLimit
      products {
        _id
        displayName
        originalName
        description
        specification
        category {
          _id
          name
          parentId
          deleted
          isFilterApplicable
          subCategories {
            _id
            name
            parentId
            deleted
            isFilterApplicable
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        status {
          ProductStatus
          DamageStatus
          PackagingStatus
          conditionNotes
        }
        filterBy
        conditionNotes
        vendorName
        images {
          src
          alt
          magnifySrc
        }
        thumbnailImage {
          src
          alt
          magnifySrc
        }
        display
        sku
        estimatedBid
        bids {
          userName
          amount
          createdAt
          updatedAt
          _id
        }
        currentBid
        currentBidder {
          _id
          firstName
          lastName
          email
        }
        watchListStatus
        isSold
        auctionDate
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
    }
  }
`;

export const ADDPRODUCT_MUTATION = gql`
  mutation AddProduct($productInput: ProductInput!) {
    addProduct(ProductInput: $productInput) {
      _id
      displayName
      originalName
      description
      specification
      category {
        _id
        name
        parentId
        deleted
        isFilterApplicable
        subCategories {
          _id
          name
          parentId
          deleted
          isFilterApplicable
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      status {
        ProductStatus
        DamageStatus
        PackagingStatus
        conditionNotes
      }
      filterBy
      conditionNotes
      vendorName
      images {
        src
        alt
        magnifySrc
      }
      thumbnailImage {
        src
        alt
        magnifySrc
      }
      display
      sku
      estimatedBid
      bids {
        _id
        userName
        amount
        createdAt
        updatedAt
      }
      currentBid
      currentBidder {
        _id
        firstName
        lastName
        email
      }
      watchListStatus
      isSold
      auctionDate
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;

export const EDITPRODUCT_MUTATION = gql`
  mutation UpdateProduct($productInput: UpdateProductInput!) {
    updateProduct(ProductInput: $productInput) {
      _id
      displayName
      originalName
      description
      specification
      category {
        _id
        name
        parentId
        deleted
        isFilterApplicable
        subCategories {
          _id
          name
          parentId
          deleted
          isFilterApplicable
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      status {
        ProductStatus
        DamageStatus
        PackagingStatus
        conditionNotes
      }
      filterBy
      conditionNotes
      vendorName
      images {
        src
        alt
        magnifySrc
      }
      thumbnailImage {
        src
        alt
        magnifySrc
      }
      display
      sku
      estimatedBid
      bids {
        _id
        userName
        amount
        createdAt
        updatedAt
      }
      currentBid
      currentBidder {
        _id
        firstName
        lastName
        email
      }
      watchListStatus
      isSold
      auctionDate
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;

export const DELETEPRODUCT_MUTATION = gql`
  mutation DeleteProduct($deleteProductId: ID!) {
    deleteProduct(id: $deleteProductId)
  }
`;
