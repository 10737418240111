import React from "react";
import { Box } from "@mui/material";

import "./ScrollbarComponant.css";

const ScrollbarComponant = (props) => {
  const { children, ...rest } = props;
  return (
    <Box className="scrollbar-componant" {...rest}>
      {children}
    </Box>
  );
};

export default ScrollbarComponant;
