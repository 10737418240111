import { useMutation, useQuery } from "@apollo/client";
import { Avatar, Box, Button, Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { MdFilterList } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import BreadcrumbsComponent from "../../../components/BreadcrumbsComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import DeleteModalComponent from "../../../components/DeleteModalComponent";
import SearchComponent from "../../../components/SearchComponent";
import TableComponent from "../../../components/TableComponent";
import { errorToaster, successToaster } from "../../../components/Toaster";
import { Routes } from "../../../enums/Routes.enum";
import { loadFile } from "../../../utils/loadFile";
import {
  DELETEPRODUCT_MUTATION,
  EDITPRODUCT_MUTATION,
  GETPRODUCTS_QUERY,
} from "../query";

const AllProduct = () => {
  const breadcrumbsArray = [{ title: "All product" }];

  const [products, setProducts] = useState([]);
  const [isDelete, setIsDelete] = useState({ visible: false, record: null });
  const [isDeleteSelected, setIsDeleteSelected] = useState({
    visible: false,
    record: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [isClearRowSelection, setIsClearRowSelection] = useState(false);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const {
    loading,
    error,
    data,
    refetch: getAllProduct,
  } = useQuery(GETPRODUCTS_QUERY, {
    variables: {
      productInput: {
        page: 1,
        limit: pageSize,
        categoryId: "",
        search: "",
        filters: ["IN_PROGRESS", "SELLER_SOLD"],
      },
    },
  });

  if (error) {
    errorToaster(error.message);
  }

  const [editProduct] = useMutation(EDITPRODUCT_MUTATION);
  const [deleteProduct] = useMutation(DELETEPRODUCT_MUTATION);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  useEffect(() => {
    setProducts(data?.getProducts?.products || []);
  }, [data]);

  useEffect(() => {
    getAllProduct({
      productInput: {
        page: currentPage,
        limit: pageSize,
        filters: ["IN_PROGRESS", "SELLER_SOLD"],
      },
    });
  }, [getAllProduct, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleEditProduct = async (productId) => {
    navigate(`/products/edit/${productId}`);
  };

  const handleViewProduct = (productId) => {
    navigate(`/products/all-product/view-product/${productId}`);
  };

  const handleDisplayProduct = async (productId, value) => {
    try {
      const productInput = {
        isSold: value,
        productId: productId,
      };
      const { data: afterEditData } = await editProduct({
        variables: { productInput },
      });
      if (afterEditData.updateProduct) {
        successToaster("Product updated successfully!");
        getAllProduct();
      }
    } catch (error) {
      console.error(error.message);
      errorToaster(error.message);
    }
  };

  const handleDeleteProduct = async (deleteProductId, isSelected = false) => {
    try {
      const { data } = await deleteProduct({ variables: { deleteProductId } });
      if (data?.deleteProduct) {
        getAllProduct();
        if (!isSelected) successToaster(data.deleteProduct);
      }
    } catch (error) {
      console.error(error);
      errorToaster(error.message);
    }
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "thumbnail",
        header: "#",
        minSize: 100,
        maxSize: 400,
        size: 50,
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => {
          const thumbnailImage = row?.original?.thumbnailImage;
          return (
            <Avatar className="avatar-contanier" variant="rounded">
              <img
                className="avatar-image"
                src={loadFile(thumbnailImage?.src)}
                alt={thumbnailImage?.alt}
              />
            </Avatar>
          );
        },
      },
      {
        accessorKey: "displayName",
        header: "Product Name",
      },
      {
        accessorKey: "category.name",
        header: "Category",
      },
    ],
    []
  );

  const handleSearch = (value) => {
    getAllProduct({
      productInput: {
        search: value,
        filters: ["IN_PROGRESS", "SELLER_SOLD"],
      },
    });
  };

  const handleAddProduct = () => {
    navigate(Routes.ADDPRODUCT);
  };

  const handleDeleteSelectedProduct = (productIds) => {
    if (productIds.length > 0) {
      productIds.forEach((productId) => {
        handleDeleteProduct(productId, true);
      });
      setIsClearRowSelection(true);
      successToaster("Products deleted successfully");
    }
  };
  return (
    <Box className="content-contanier">
      <BreadcrumbsComponent breadcrumbs={breadcrumbsArray} />
      <DeleteModalComponent
        isDelete={isDelete}
        setIsDelete={setIsDelete}
        deleteRecord={handleDeleteProduct}
      />
      <DeleteModalComponent
        isDelete={isDeleteSelected}
        setIsDelete={setIsDeleteSelected}
        deleteRecord={handleDeleteSelectedProduct}
      />
      <Grid container spacing={2} className="content-filter-container">
        <Grid item xs={6}>
          <SearchComponent handleSearch={handleSearch} />
        </Grid>
        <Grid item xs={6} className="filter-section">
          <Button className="filter-icon-contanier">
            <MdFilterList />
          </Button>
          <ButtonComponent label="Add Product" onClick={handleAddProduct} />
        </Grid>
      </Grid>

      <TableComponent
        columns={columns}
        data={products}
        hasCopyBtn={true}
        handleEdit={handleEditProduct}
        setIsDelete={setIsDelete}
        setIsDeleteSelected={setIsDeleteSelected}
        isManualPagination={true}
        rowCount={data?.getProducts?.total || 0}
        handlePageChange={handlePageChange}
        density="compact"
        isClearRowSelection={isClearRowSelection}
        loader={loader}
        enableActions={true}
        handleDisplayProduct={handleDisplayProduct}
        handleViewProduct={handleViewProduct}
        isShowProductView={true}
        isSoldView={true}
      />
    </Box>
  );
};

export default AllProduct;
