import { gql } from "@apollo/client";

export const GET_EMPLOYEE = gql`
  query GetEmployee($getEmployeeInput: GetEmployeeInput) {
    getEmployee(GetEmployeeInput: $getEmployeeInput) {
      totalPages
      total
      currentPage
      currentLimit
      employees {
        _id
        firstName
        lastName
        email
        role
        sellerId
        mobileNumber
      }
    }
  }
`;

export const CREATE_EMPLOYEE = gql`
  mutation CreateEmployee($employeeInput: CreateEmployeeInput) {
    createEmployee(EmployeeInput: $employeeInput) {
      _id
      email
      firstName
      lastName
      role
      sellerId
    }
  }
`;
export const UPDATE_EMPLOYEE = gql`
  mutation UpdateEmployee($employeeInput: UpdateEmployeeInput) {
    updateEmployee(EmployeeInput: $employeeInput) {
      _id
      email
      firstName
      lastName
      role
      sellerId
    }
  }
`;

export const DELETE_EMPLOYEE = gql`
  mutation DeleteEmployee($deleteEmployeeId: ID!) {
    deleteEmployee(id: $deleteEmployeeId) {
      _id
      email
      firstName
      lastName
      role
      sellerId
    }
  }
`;
