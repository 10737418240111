import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ScrollbarComponant from "../../components/ScrollbarComponant";
import { privateRoutes } from "../../routes";
import { getLocalStorageItem } from "../../utils/localStorage";
import "./Sidebar.css";

const Sidebar = ({ drawerWidth, isSidebarOpen, setIsSidebarOpen }) => {
  const { role } = getLocalStorageItem("user", true);
  const location = useLocation();
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();

  const handleToggle = (path) => {
    setOpen((prev) => (prev === path ? null : path));
  };

  const handleMainRouteClick = (route) => {
    navigate(route.path);
    handleToggle(route.path);
  };

  const handleRoute = (route) => {
    if (route.subRoutes) {
      handleToggle(route.path);
    } else {
      handleMainRouteClick(route);
    }
  };
  return (
    <Drawer
      sx={{
        width: {
          sm: isSidebarOpen ? drawerWidth : 0,
        },
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isSidebarOpen ? drawerWidth : 0,
          boxSizing: "border-box",
          borderTop: "1px solid #0000001f",
          top: {
            xs: "56px",
            sm: "64px",
          },
        },
      }}
      className="sidebar"
      variant="persistent"
      anchor="left"
      open={isSidebarOpen}
      onClose={() => setIsSidebarOpen(false)}
    >
      <ScrollbarComponant
        sx={{
          height: {
            xs: "calc(100vh - 56px)",
            sm: "calc(100vh - 64px)",
          },
        }}
      >
        <List>
          {privateRoutes
            .filter((route) => route.label)
            .filter((route) => route.roles.includes(role))
            .map((route) => (
              <React.Fragment key={route.path}>
                <ListItem
                  className={`sidebar-list ${
                    location?.pathname.includes(route.path) ? "active" : ""
                  }`}
                  component={route.subRoutes ? "div" : Link}
                  to={route.subRoutes ? "#" : route.path}
                  onClick={() => handleRoute(route)}
                >
                  <ListItemIcon className="icon">{route?.icon}</ListItemIcon>
                  <ListItemText primary={route.label} />
                </ListItem>
                {route.subRoutes && (
                  <Collapse
                    in={open === route.path}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      style={{ marginLeft: "10px" }}
                      disablePadding
                    >
                      {route.subRoutes.map((subRoute) => (
                        <ListItem
                          button
                          component={Link}
                          to={subRoute.path}
                          key={subRoute.path}
                          className={`sidebar-list ${
                            location?.pathname.includes(subRoute.path)
                              ? "active"
                              : ""
                          }`}
                        >
                          <ListItemIcon className="icon">
                            {subRoute?.icon}
                          </ListItemIcon>
                          <ListItemText primary={subRoute.label} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
        </List>
      </ScrollbarComponant>
    </Drawer>
  );
};

export default Sidebar;
