import React, { useEffect, useState } from "react";
import {
  MRT_SelectCheckbox,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button, CircularProgress, IconButton } from "@mui/material";
import { MdDelete, MdDownload, MdEdit, MdRemoveRedEye } from "react-icons/md";
import "./TableComponent.css";
import { useNavigate } from "react-router-dom";
import { OrderStatus } from "../../enums/Constants.enum";

const TableComponent = ({
  isExanding = false,
  columns,
  data,
  subRowName = "",
  handleEdit = () => {},
  setIsDelete = {},
  isManualPagination = false,
  rowCount,
  handlePageChange,
  density,
  setIsDeleteSelected,
  isClearRowSelection = false,
  customRowActions = false,
  loader = false,
  handleStatusButtonClick = () => {},
  loading,
  enableActions,
  handleDisplayProduct,
  handleViewProduct,
  isShowProductView,
  isInvoiceView,
  handleViewInvoice,
  isSoldView,
  handleViewInvoicePdf,
}) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowSelection, setRowSelection] = useState({});
  const [selectedButton, setSelectedButton] = useState(0);
  const [selectedId, setSelectedId] = useState("");

  const handleToggleButton = (rowId, currentStatus) => {
    const newStatus = !currentStatus;
    handleDisplayProduct(rowId, newStatus);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (handlePageChange) {
      handlePageChange(pagination.pageIndex + 1);
    }
  }, [pagination.pageIndex, pagination.pageSize, pagination]);

  const handleCustomButtonClick = (id, status, btn) => {
    setSelectedId(id);
    setSelectedButton(btn);
    handleStatusButtonClick(id, status);
  };

  useEffect(() => {
    if (isClearRowSelection) setRowSelection({});
  }, [isClearRowSelection]);

  const table = useMaterialReactTable({
    columns,
    data,
    enableExpandAll: false,
    enableExpanding: isExanding,
    paginateExpandedRows: true,
    displayColumnDefOptions: {
      "mrt-row-expand": {
        size: 40,
      },
    },
    enableGlobalFilter: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableTopToolbar: false,
    enableRowSelection: enableActions,
    // selectAllMode: "all",
    renderToolbarAlertBannerContent: ({ selectedAlert, table }) => (
      <>
        {enableActions && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                gap: "6px",
                p: "4px 12px",
                width: "100%",
              }}
            >
              {/* Render a custom select checkbox component */}
              <MRT_SelectCheckbox table={table} /> {selectedAlert}
            </Box>
            <Box sx={{ display: "flex", gap: "6px" }}>
              <Button
                color="error"
                startIcon={<MdDelete />}
                variant="contained"
                onClick={() =>
                  setIsDeleteSelected({
                    visible: true,
                    record: Object.keys(table.getState().rowSelection),
                  })
                }
              >
                Delete
              </Button>
            </Box>
          </Box>
        )}
      </>
    ),
    getRowId: (row) => row._id,
    onRowSelectionChange: setRowSelection,
    positionToolbarAlertBanner: "head-overlay",
    getSubRows: (row) => row[subRowName],
    initialState: {
      showColumnFilters: false,
      columnPinning: { right: ["mrt-row-actions"] },
      density: density || "comfortable",
    },
    muiTablePaperProps: {
      sx: { display: "flex", flexDirection: "column", height: "100%" },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.original.isSold
          ? "#ffebee"
          : [OrderStatus.DISPUTE, OrderStatus.REFUND].includes(
              row.original.status
            )
          ? "#ffebee"
          : "#fff",
      },
      onClick: () => {
        if (isShowProductView) {
          handleViewProduct(row.original._id);
        }
        if (isInvoiceView) {
          handleViewInvoice(row.original._id);
        }
      },
    }),
    muiTableContainerProps: {
      sx: {
        height: "100%",
        "&::-webkit-scrollbar": {
          width: "16px",
          height: "16px",
        },
        "&::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb": {
          borderRadius: "16px",
          border: "solid 5px transparent",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 5px 5px rgba(0, 0, 0, 0.075)",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 5px 5px rgba(0, 0, 0, 0.35)",
        },
      },
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10],
      showRowsPerPage: false,
    },
    manualPagination: isManualPagination,
    rowCount: rowCount,
    onPaginationChange: setPagination,
    state: { isLoading: loader, pagination, rowSelection },
    enableRowActions: enableActions,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) =>
      customRowActions ? (
        // Custom button based on the status
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            justifyContent: "left",
          }}
        >
          {row.original.status === OrderStatus.ORDER_PLACED && (
            <Button
              variant="contained"
              color="primary"
              style={{ width: "100px" }}
              disabled={
                row.original.status === OrderStatus.REFUND ||
                (loading && selectedButton === 1 && selectedId === row.id)
              }
              onClick={() =>
                handleCustomButtonClick(row.id, OrderStatus.ON_THE_WAY, 1)
              }
            >
              {loading && selectedButton === 1 && selectedId === row.id ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Start"
              )}
            </Button>
          )}
          {row.original.status === OrderStatus.ON_THE_WAY && (
            <Button
              variant="contained"
              color="secondary"
              style={{ width: "100px" }}
              disabled={
                [OrderStatus.REFUND, OrderStatus.DISPUTE].includes(
                  row.original.status
                ) ||
                (loading && selectedButton === 1 && selectedId === row.id)
              }
              onClick={() =>
                handleCustomButtonClick(row.id, OrderStatus.DELIVERED, 1)
              }
            >
              {loading && selectedButton === 1 && selectedId === row.id ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Delivered"
              )}
            </Button>
          )}
          {row.original.status === OrderStatus.DELIVERED && (
            <Button variant="contained" style={{ width: "100px" }} disabled>
              Finish
            </Button>
          )}
          {[OrderStatus.DISPUTE, OrderStatus.REFUND].includes(
            row.original.status
          ) && (
            <Button variant="contained" style={{ width: "100px" }} disabled>
              --
            </Button>
          )}
          {![OrderStatus.DISPUTE, OrderStatus.REFUND].includes(
            row.original.status
          ) && (
            <Button
              variant="contained"
              color="error"
              style={{ width: "100px" }}
              onClick={() =>
                handleCustomButtonClick(row.id, OrderStatus.DISPUTE, 2)
              }
              disabled={
                loading && selectedButton === 2 && selectedId === row.id
              }
            >
              {loading && selectedButton === 2 && selectedId === row.id ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                OrderStatus.DISPUTE
              )}
            </Button>
          )}
          {[OrderStatus.DISPUTE].includes(row.original.status) && (
            <Button
              variant="contained"
              color="error"
              style={{ width: "100px" }}
              onClick={() =>
                handleCustomButtonClick(row.id, OrderStatus.REFUND, 2)
              }
              disabled={
                loading && selectedButton === 2 && selectedId === row.id
              }
            >
              {loading && selectedButton === 2 && selectedId === row.id ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "REFUND"
              )}
            </Button>
          )}
        </Box>
      ) : (
        // Default actions: Edit and Delete buttons
        <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
          {!isInvoiceView && (
            <IconButton
              color="success"
              onClick={(event) => {
                event.stopPropagation();
                handleEdit(row.id);
              }}
            >
              <MdEdit />
            </IconButton>
          )}
          {!isInvoiceView && (
            <IconButton
              color="error"
              onClick={(event) => {
                event.stopPropagation();
                setIsDelete({ visible: true, record: row.id });
              }}
            >
              <MdDelete />
            </IconButton>
          )}
          {isSoldView && (
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => {
                event.stopPropagation();
                handleToggleButton(row.id, row.original.isSold);
              }}
            >
              {row.original.isSold ? "Unsold" : "Sold"}
            </Button>
          )}
          {isInvoiceView && (
            <IconButton
              variant="contained"
              color="primary"
              onClick={async (event) => {
                event.stopPropagation();
                await handleViewInvoicePdf(row?.original);
              }}
            >
              <MdDownload />
            </IconButton>
          )}
        </Box>
      ),
  });
  return <MaterialReactTable table={table} />;
};

export default TableComponent;
