import React from "react";
import { Box, TextField } from "@mui/material";
import "./phoneComponent.css";

const PhoneComponent = ({
  type = "text",
  id,
  label,
  name,
  onChange,
  onBlur,
  value,
  errors,
  touched,
  width,
  pattern,
  maxLength
}) => {
  return (
    <Box className="input-container" sx={{ width: width || "100%" }}>
      <TextField
        type={type}
        className="input-text-field"
        id={id}
        label={label}
        name={name}
        variant="outlined"
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        InputLabelProps={{
          shrink: value ? true : false,
          sx: { lineHeight: "unset", fontSize: "14px", top: "-5px" }
        }}
        inputProps={{
          autoComplete: "new-password",
          maxLength: maxLength || undefined,
          pattern: pattern || undefined
        }}
        InputProps={{
          sx: {
            "& input": {
              padding: "10px 12px",
              fontSize: "14px"
            },
            borderRadius: "8px"
          }
        }}
      />
      {errors && touched && <Box className="input-error">{errors}</Box>}
    </Box>
  );
};

export default PhoneComponent;
