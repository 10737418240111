import { Breadcrumbs, Link as MuiLink, Typography } from "@mui/material";
import React from "react";
import "./BreadcrumbsComponent.css";
import { MdNavigateNext } from "react-icons/md";
import { Link } from "react-router-dom";

const BreadcrumbsComponent = ({ breadcrumbs }) => {
  return (
    <Breadcrumbs separator={<MdNavigateNext />} aria-label="breadcrumb">
      {breadcrumbs.map((breadcrumb, index) => {
        return breadcrumb.link ? (
          <MuiLink
            component={Link}
            key={index}
            underline="hover"
            color="inherit"
            to={breadcrumb.link}
          >
            {breadcrumb.title}
          </MuiLink>
        ) : (
          <Typography key={index} className="breadcrumb-text">
            {breadcrumb.title}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbsComponent;
