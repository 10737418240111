import React from "react";

/* Components */
import Category from "../pages/Category";
import Dashboard from "../pages/Dashboard";
import Employee from "../pages/Employee";
import AddorEditEmployee from "../pages/Employee/AddorEditEmployee";
import Products from "../pages/Products";
import AddorEditProduct from "../pages/Products/AddorEditProduct";

import {
  MdList,
  MdOutlineDashboard,
  MdOutlineListAlt,
  MdOutlineSell,
  MdOutlineSupervisedUserCircle,
  MdProductionQuantityLimits,
  MdRequestQuote,
  MdVerified,
} from "react-icons/md";
import { ROLES } from "../enums/Roles.enum";
import { RouteNames, Routes } from "../enums/Routes.enum";
import Invoice from "../pages/Invoice";
import InvoiceDetail from "../pages/Invoice/InvoiceDetails";
import AllProduct from "../pages/Products/AllProduct";
import SoldProduct from "../pages/Products/SoldProduct";
import UnderWarrantyProduct from "../pages/Products/UnwantedWarrantyProduct";
import ViewProduct from "../pages/Products/ViewProduct";

export const privateRoutes = [
  {
    path: Routes.DASHBOARD,
    label: RouteNames.DASHBOARD,
    cmp: <Dashboard />,
    roles: [ROLES.ADMIN, ROLES.EMPLOYEE],
    icon: <MdOutlineDashboard />,
  },
  {
    path: Routes.EMPLOYEE,
    label: RouteNames.EMPLOYEE,
    cmp: <Employee />,
    roles: [ROLES.ADMIN],
    icon: <MdOutlineSupervisedUserCircle />,
  },
  {
    path: Routes.ADD_EMPLOYEE,
    roles: [ROLES.ADMIN],
    cmp: <AddorEditEmployee />,
  },
  {
    path: Routes.EDIT_EMPLOYEE,
    roles: [ROLES.ADMIN],
    cmp: <AddorEditEmployee />,
  },
  {
    path: Routes.PRODUCTS,
    label: RouteNames.PRODUCTS,
    roles: [ROLES.ADMIN, ROLES.EMPLOYEE],
    cmp: <Products />,
    icon: <MdOutlineListAlt />,
    subRoutes: [
      {
        path: Routes.ALLPRODUCT,
        label: RouteNames.ALLPRODUCT,
        cmp: <AllProduct />,
        roles: [ROLES.ADMIN, ROLES.EMPLOYEE],
        icon: <MdProductionQuantityLimits />,
      },
      {
        path: Routes.UNDERWARRANTYPRODUCT,
        label: RouteNames.UNDERWARRANTYPRODUCT,
        cmp: <UnderWarrantyProduct />,
        roles: [ROLES.ADMIN, ROLES.EMPLOYEE],
        icon: <MdVerified />,
      },
      {
        path: Routes.SOLDPRODUCT,
        label: RouteNames.SOLDPRODUCT,
        cmp: <SoldProduct />,
        roles: [ROLES.ADMIN, ROLES.EMPLOYEE],
        icon: <MdOutlineSell />,
      },
    ],
  },
  {
    path: Routes.CATEGORY,
    label: RouteNames.CATEGORY,
    roles: [ROLES.ADMIN, ROLES.EMPLOYEE],
    cmp: <Category />,
    icon: <MdList />,
  },
  {
    path: Routes.INVOICE,
    label: RouteNames.INVOICE,
    roles: [ROLES.ADMIN],
    cmp: <Invoice />,
    icon: <MdRequestQuote />,
  },
  {
    path: Routes.ADDPRODUCT,
    roles: [ROLES.ADMIN, ROLES.EMPLOYEE],
    cmp: <AddorEditProduct />,
  },
  {
    path: Routes.EDITPRODUCT,
    roles: [ROLES.ADMIN, ROLES.EMPLOYEE],
    cmp: <AddorEditProduct />,
  },
  {
    path: Routes.VIEWPRODUCT,
    roles: [ROLES.ADMIN, ROLES.EMPLOYEE],
    cmp: <ViewProduct />,
  },
  {
    path: Routes.INVOICEDETAILS,
    roles: [ROLES.ADMIN],
    cmp: <InvoiceDetail />,
  },
];
