import React, { useEffect, useState } from "react";
import { Box, styled } from "@mui/material";
import { useLocation, Navigate } from "react-router-dom";

import { publicRoutes, privateRoutes } from "../routes";

import {
  getLocalStorageItem,
  setLocalStorageItem
} from "../utils/localStorage";

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import ScrollbarComponant from "../components/ScrollbarComponant";

const drawerWidth = 250;

const BodyWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  @media (max-width: 767px) {
    display: block;
  }
`;

const Body = styled(Box)(({ issidebaropen }) => ({
  width: `calc(100vw - ${
    issidebaropen === "true" ? 282 : 282 - drawerWidth
  }px)`,
  padding: "16px 0",
  height: "calc(100vh - 96px)",
  "@media (max-width: 767px)": {
    width: "100%"
  }
}));

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const token = getLocalStorageItem("accessToken");
  const isPublicRoute = publicRoutes.some((route) => route.path === pathname);
  const isPrivateRoute = privateRoutes.some((route) => {
    return pathname.includes(route.path);
  });

  if (isPublicRoute) {
    return !token ? (
      <PublicLayout component={children} />
    ) : (
      <>
        <Navigate to="/dashboard" />
      </>
    );
  }

  if (!token) {
    return <Navigate to="/login" />;
  }

  return pathname === "/" || !isPrivateRoute ? (
    <Navigate to="/dashboard" />
  ) : (
    <PrivateLayout component={children} />
  );
};

const PublicLayout = ({ component }) => {
  return <React.Fragment>{component}</React.Fragment>;
};

const PrivateLayout = ({ component }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    setTheme(getLocalStorageItem("currentTheme") || "light");
  }, []);

  const toggleTheme = (newTheme) => {
    setTheme(newTheme);
    setLocalStorageItem("currentTheme", newTheme);
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Box className={`${theme}-theme`}>
      <Navbar
        toggleTheme={toggleTheme}
        currentTheme={theme}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <BodyWrapper>
        <Sidebar
          drawerWidth={drawerWidth}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <ScrollbarComponant
          sx={{
            height: {
              xs: "calc(100vh - 56px)",
              sm: "calc(100vh - 64px)"
            }
          }}
        >
          <Body issidebaropen={isSidebarOpen.toString()}>{component}</Body>
        </ScrollbarComponant>
      </BodyWrapper>
    </Box>
  );
};

export default Layout;
