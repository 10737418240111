import { React, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { GET_SELLER_INVOICES } from "../query";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Email as EmailIcon, Phone as PhoneIcon } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { loadFile } from "../../../utils/loadFile";
import BreadcrumbsComponent from "../../../components/BreadcrumbsComponent";
import applogo from "../../../assets/AppLogo/LOGO.png";
import moment from "moment";
import { MdDownload } from "react-icons/md";
import InvoiceComponent from "../../../components/invoiceComponent";
import generatePDF from "react-to-pdf";

const InvoiceDetail = () => {
  const breadcrumbsArray = [
    { title: "Invoice", link: "/invoice" },
    { title: "View Invoice" },
  ];
  const { invoiceId } = useParams();
  const invoiceRef = useRef(null);
  const [showInvoice, setShowInvoice] = useState(true);
  const [invoiceDetails, setInvoiceDetails] = useState(null);

  const { data } = useQuery(GET_SELLER_INVOICES, {
    variables: { invoicesInput: { id: invoiceId } },
  });

  useEffect(() => {
    if (data) {
      setInvoiceDetails(data.getSellerInvoices.invoices[0]);
    }
  }, [data]);

  const handleDownload = async () => {
    setShowInvoice(false);
    const content = () => {
      let element = invoiceRef.current;
      if (element) {
        element.style.display = "block";
      }
      return element;
    };
    const options = {
      filename: `invoice-${invoiceDetails?.invoiceNumber || "invoice"}.pdf`,
      page: {
        margin: 20,
      },
    };

    try {
      await generatePDF(content, options);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setShowInvoice(true);
    }
  };

  return (
    <>
      <Box className="content-form-detail-contanier" sx={{ marginBottom: 10 }}>
        <BreadcrumbsComponent breadcrumbs={breadcrumbsArray} />
        <Box display="flex" justifyContent="flex-end" p={2} borderRadius={1}>
          <IconButton
            onClick={handleDownload}
            sx={{
              color: "white",
              bgcolor: "#c1761f",
              "&:hover": {
                bgcolor: "#c1761f",
              },
            }}
          >
            <MdDownload />
          </IconButton>
        </Box>
        <Box
          sx={{
            maxWidth: "47rem",
            mx: "auto",
            bgcolor: "white",
            boxShadow: 3,
            mt: 2,
            borderRadius: 2,
          }}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            color="black"
            textAlign="center"
            padding="10px"
          >
            Invoice
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <Grid item>
              <Typography color="black">
                <span style={{ color: "black", fontWeight: "bold" }}>
                  Invoice Number :
                </span>{" "}
                #{invoiceDetails?.invoiceNumber ?? "-"}
              </Typography>
              <Typography color="black">
                <span style={{ color: "black", fontWeight: "bold" }}>
                  GST/HST :
                </span>{" "}
                {invoiceDetails?.seller?.gstNumber ?? "-"}
              </Typography>
              <Typography color="black">
                <span style={{ color: "black", fontWeight: "bold" }}>
                  Date :
                </span>{" "}
                {moment(invoiceDetails?.createdAt).format("DD/MM/YYYY") ?? "-"}
              </Typography>
            </Grid>
            <Grid item>
              <img src={applogo} width={170} height={85} alt="App Logo" />
            </Grid>
          </Grid>

          <Box sx={{ mt: 2, px: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" fontWeight="bold" color="black">
                  Seller :
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <Box sx={{ display: "flex" }}>
                    <Typography fontWeight="bold" color="black">
                      User Name:
                    </Typography>
                    <Typography sx={{ color: "black", ml: 1 }}>
                      {invoiceDetails?.seller?.companyName ?? "-"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography fontWeight="bold" color="black">
                      First Name:
                    </Typography>
                    <Typography sx={{ color: "black", ml: 1 }}>
                      {invoiceDetails?.seller?.firstName ?? "-"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography fontWeight="bold" color="black">
                      Last Name:
                    </Typography>
                    <Typography sx={{ color: "black", ml: 1 }}>
                      {invoiceDetails?.seller?.lastName ?? "-"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography fontWeight="bold" color="black">
                      Email:
                    </Typography>
                    <Typography sx={{ color: "black", ml: 1 }}>
                      {invoiceDetails?.seller?.email ?? "-"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" fontWeight="bold" color="black">
                  Dates :
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <Box sx={{ display: "flex" }}>
                    <Typography fontWeight="bold" color="black">
                      Due Date:
                    </Typography>
                    <Typography sx={{ color: "black", ml: 1 }}>
                      {invoiceDetails?.dueDate
                        ? moment(invoiceDetails?.dueDate).format("DD/MM/YYYY")
                        : "-"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography fontWeight="bold" color="black">
                      Pickup Date:
                    </Typography>
                    <Typography sx={{ color: "black", ml: 1 }}>
                      {invoiceDetails?.pickupDate
                        ? moment(invoiceDetails?.pickupDate).format(
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography fontWeight="bold" color="black">
                      Paid Date:
                    </Typography>
                    <Typography sx={{ color: "black", ml: 1 }}>
                      {invoiceDetails?.paidDate
                        ? moment(invoiceDetails?.paidDate).format("DD/MM/YYYY")
                        : "-"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography fontWeight="bold" color="black">
                      Auction Date:
                    </Typography>
                    <Typography sx={{ color: "black", ml: 1 }}>
                      {invoiceDetails?.auctionDate
                        ? moment(invoiceDetails?.auctionDate).format(
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ mt: 2, px: 3 }}>
            <TableContainer>
              <Table>
                <TableHead
                // sx={{
                //   bgcolor: "black",
                // }}
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "1rem",
                      }}
                    >
                      Image
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "1rem",
                      }}
                    >
                      Item
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "1rem",
                      }}
                      align="right"
                    >
                      Price
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceDetails?.products?.map((product, index) => (
                    <TableRow key={index} className="border-b">
                      <TableCell>
                        <img
                          src={loadFile(product?.thumbnailImage?.src)}
                          width={45}
                          height={45}
                          className="w-14 h-12 rounded-lg shadow-sm object-contain"
                        />
                      </TableCell>
                      <TableCell>
                        <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                          {product?.name}
                        </span>
                        <div
                          className="text-black"
                          style={{ marginTop: "4px" }}
                        >
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "600",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <span>SKU</span>:{" "}
                              <span
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "normal",
                                }}
                              >
                                {product?.sku}
                              </span>
                            </span>
                            <span>
                              <span>Warranty deadline</span>:{" "}
                              <span
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "normal",
                                }}
                              >
                                {invoiceDetails?.warrantyDeadline
                                  ? moment(
                                      invoiceDetails?.warrantyDeadline
                                    ).format("DD/MM/YYYY")
                                  : "-"}
                              </span>
                            </span>
                          </p>
                        </div>
                      </TableCell>

                      <TableCell align="right">
                        <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                          ${product?.sellingPrice ?? 0}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box sx={{ mt: 2, px: 3 }}>
            <Typography variant="h6" fontWeight="bold" color="black">
              Summary
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography fontWeight="bold" color="black">
                  Sub Total:
                </Typography>
                <Typography sx={{ color: "black" }}>
                  ${(invoiceDetails?.subTotal ?? 0).toFixed(2)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography fontWeight="bold" color="black">
                  HST ({invoiceDetails?.HST}%):
                </Typography>
                <Typography sx={{ color: "black" }}>
                  $
                  {(
                    (invoiceDetails?.subTotal || 0) *
                    ((invoiceDetails?.HST || 0) / 100)
                  ).toFixed(2)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography fontWeight="bold" color="black">
                  GST ({invoiceDetails?.GST}%):
                </Typography>
                <Typography sx={{ color: "black" }}>
                  $
                  {(
                    (invoiceDetails?.subTotal || 0) *
                    ((invoiceDetails?.GST || 0) / 100)
                  ).toFixed(2)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography fontWeight="bold" color="black">
                  Platform Charges ({invoiceDetails?.PL_Charges}%):
                </Typography>
                <Typography sx={{ color: "black" }}>
                  ${(invoiceDetails?.PL_Charges ?? 0).toFixed(2)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography fontWeight="bold" color="black">
                  Extra Charges:
                </Typography>
                <Typography sx={{ color: "black" }}>
                  ${(invoiceDetails?.EX_Charges ?? 0).toFixed(2)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography fontWeight="bold" color="black">
                  Shipping Fee:
                </Typography>
                <Typography sx={{ color: "black" }}>
                  ${(invoiceDetails?.shippingFee ?? 0).toFixed(2)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderTop: "1px solid gray",
                  pt: 1,
                  mt: 2,
                }}
              >
                <Typography fontWeight="bold" color="black">
                  Total:
                </Typography>
                <Typography fontWeight="bold" sx={{ color: "black" }}>
                  ${(invoiceDetails?.total ?? 0).toFixed(2)}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              bgcolor: "black",
              display: "flex",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <Box sx={{ display: "flex", gap: 2, color: "white" }}>
              <IconButton href="mailto:store@sotrenearme.ca" color="inherit">
                <EmailIcon />
                <span style={{ fontSize: "15px" }}>store@sotrenearme.ca</span>
              </IconButton>
              <IconButton href="tel:+14167592942" color="inherit">
                <PhoneIcon />
                <span style={{ fontSize: "15px" }}>+14167592942</span>
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>

      <div>
        {showInvoice && (
          <InvoiceComponent invoiceDetails={invoiceDetails} ref={invoiceRef} />
        )}
      </div>
    </>
  );
};

export default InvoiceDetail;
