import { Box, Card } from "@mui/material";
import React from "react";
import "./CardComponent.css";

export const CardComponent = ({ children, name }) => {
  return (
    <Card variant="outlined" className="card-contanier">
      {name && (
        <Box className="card-title">
          <h3>{name}</h3>
        </Box>
      )}
      {children}
    </Card>
  );
};
