import { useEffect, useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";
import { GET_CATEGORIES_QUERY } from "./query";
import { useQuery } from "@apollo/client";
import { errorToaster } from "../../components/Toaster";
import moment from "moment";
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent";
import SearchComponent from "../../components/SearchComponent";
import TableComponent from "../../components/TableComponent";

const Category = () => {
  const breadcrumbsArray = [{ title: "Category" }];
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const {
    error,
    data,
    refetch: getAllCategory,
  } = useQuery(GET_CATEGORIES_QUERY, {
    variables: {
      categoryInput: {
        limit: pageSize,
        page: 1,
        filters: null,
        search: "",
        sortOrder: null,
      },
    },
  });

  useEffect(() => {
    getAllCategory({
      categoryInput: { page: currentPage, limit: pageSize },
    });
  }, [currentPage]);

  useEffect(() => {
    if (data && data?.getCategories?.categories?.length) {
      const formattedCategories = data?.getCategories?.categories.map(
        (category) => ({
          ...category,
          createdAt: moment(parseInt(category?.createdAt)).format(
            "MMMM D, YYYY, hh:mm A"
          ),
          updatedAt: moment(parseInt(category?.updatedAt)).format(
            "MMMM D, YYYY, hh:mm A"
          ),
          subCategories: category?.subCategories?.map((subCategory) => ({
            ...subCategory,
            createdAt: moment(parseInt(subCategory?.createdAt)).format(
              "MMMM D, YYYY, hh:mm A"
            ),
            updatedAt: moment(parseInt(subCategory?.updatedAt)).format(
              "MMMM D, YYYY, hh:mm A"
            ),
          })),
        })
      );
      setCategories(formattedCategories);
    }
  }, [data, currentPage, getAllCategory]);

  if (error) {
    errorToaster(error?.message);
  }

  const handleSearch = (searchValue) => {
    getAllCategory({ categoryInput: { search: searchValue } });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Category Name",
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
      },
      {
        accessorKey: "updatedAt",
        header: "Updated At",
      },
    ],
    []
  );

  return (
    <Box className="content-contanier">
      <BreadcrumbsComponent breadcrumbs={breadcrumbsArray} />
      <Grid container spacing={2} className="content-filter-container">
        <Grid item xs={6}>
          <SearchComponent handleSearch={handleSearch} />
        </Grid>
        <Grid item xs={6} className="filter-section"></Grid>
      </Grid>

      <TableComponent
        columns={columns}
        data={categories}
        isExanding={true}
        subRowName="subCategories"
        handleEdit={() => {}}
        setIsDelete={""}
        setIsDeleteSelected={""}
        isClearRowSelection={false}
        handlePageChange={handlePageChange}
        isManualPagination={true}
        rowCount={data?.getCategories?.total || 0}
        enableActions={false}
      />
    </Box>
  );
};

export default Category;
