import React, { forwardRef, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Hidden,
} from "@mui/material";
import moment from "moment";
import applogo from "../../assets/AppLogo/LOGO.png";
import { Email as EmailIcon, Phone as PhoneIcon } from "@mui/icons-material";
const InvoiceComponent = forwardRef(({ invoiceDetails }, ref) => {
  return (
    <div>
      <Box className="content-form-detail-contanier">
        <Box
          sx={{
            width: "210mm",
            bgcolor: "white",
            borderRadius: 2,
          }}
          ref={ref}
          style={{ display: "none" }}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            color="black"
            textAlign="center"
            padding="10px"
          >
            Invoice
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <Grid item>
              <Typography color="black">
                <span style={{ color: "black", fontWeight: "bold" }}>
                  Invoice Number :
                </span>{" "}
                #{invoiceDetails?.invoiceNumber ?? "-"}
              </Typography>
              <Typography color="black">
                <span style={{ color: "black", fontWeight: "bold" }}>
                  GST/HST :
                </span>{" "}
                {invoiceDetails?.seller?.gstNumber ?? "-"}
              </Typography>
              <Typography color="black">
                <span style={{ color: "black", fontWeight: "bold" }}>
                  Date :
                </span>{" "}
                {moment(invoiceDetails?.createdAt).format("DD/MM/YYYY") ?? "-"}
              </Typography>
            </Grid>
            <Grid item>
              <img src={applogo} width={170} height={85} alt="App Logo" />
            </Grid>
          </Grid>

          <Box sx={{ mt: 2, px: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" fontWeight="bold" color="black">
                  Seller :
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <Box sx={{ display: "flex" }}>
                    <Typography fontWeight="bold" color="black">
                      User Name:
                    </Typography>
                    <Typography sx={{ color: "black", ml: 1 }}>
                      {invoiceDetails?.seller?.companyName ?? "-"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography fontWeight="bold" color="black">
                      First Name:
                    </Typography>
                    <Typography sx={{ color: "black", ml: 1 }}>
                      {invoiceDetails?.seller?.firstName ?? "-"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography fontWeight="bold" color="black">
                      Last Name:
                    </Typography>
                    <Typography sx={{ color: "black", ml: 1 }}>
                      {invoiceDetails?.seller?.lastName ?? "-"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography fontWeight="bold" color="black">
                      Email:
                    </Typography>
                    <Typography sx={{ color: "black", ml: 1 }}>
                      {invoiceDetails?.seller?.email ?? "-"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" fontWeight="bold" color="black">
                  Dates :
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <Box sx={{ display: "flex" }}>
                    <Typography fontWeight="bold" color="black">
                      Due Date:
                    </Typography>
                    <Typography sx={{ color: "black", ml: 1 }}>
                      {invoiceDetails?.dueDate
                        ? moment(invoiceDetails?.dueDate).format("DD/MM/YYYY")
                        : "-"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography fontWeight="bold" color="black">
                      Pickup Date:
                    </Typography>
                    <Typography sx={{ color: "black", ml: 1 }}>
                      {invoiceDetails?.pickupDate
                        ? moment(invoiceDetails?.pickupDate).format(
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography fontWeight="bold" color="black">
                      Paid Date:
                    </Typography>
                    <Typography sx={{ color: "black", ml: 1 }}>
                      {invoiceDetails?.paidDate
                        ? moment(invoiceDetails?.paidDate).format("DD/MM/YYYY")
                        : "-"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography fontWeight="bold" color="black">
                      Auction Date:
                    </Typography>
                    <Typography sx={{ color: "black", ml: 1 }}>
                      {invoiceDetails?.auctionDate
                        ? moment(invoiceDetails?.auctionDate).format(
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ mt: 2, px: 3 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        color: "black",
                      }}
                    >
                      Item
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        color: "black",
                      }}
                      align="right"
                    >
                      Price
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceDetails?.products?.map((product, index) => (
                    <TableRow key={index} className="border-b">
                      <TableCell>
                        <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                          {product?.name}
                        </span>
                        <div
                          className="text-black"
                          style={{ marginTop: "4px" }}
                        >
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "600",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <span>SKU</span>:{" "}
                              <span
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "normal",
                                }}
                              >
                                {product?.sku}
                              </span>
                            </span>
                            <span>
                              <span>Warranty deadline</span>:{" "}
                              <span
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "normal",
                                }}
                              >
                                {invoiceDetails?.warrantyDeadline
                                  ? moment(
                                      invoiceDetails?.warrantyDeadline
                                    ).format("DD/MM/YYYY")
                                  : "-"}
                              </span>
                            </span>
                          </p>
                        </div>
                      </TableCell>

                      <TableCell align="right">
                        <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                          ${product?.sellingPrice ?? 0}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box sx={{ mt: 2, px: 3 }}>
            <Typography variant="h6" fontWeight="bold" color="black">
              Summary
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography fontWeight="bold" color="black">
                  Sub Total:
                </Typography>
                <Typography sx={{ color: "black" }}>
                  ${(invoiceDetails?.subTotal ?? 0).toFixed(2)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography fontWeight="bold" color="black">
                  HST ({invoiceDetails?.HST}%):
                </Typography>
                <Typography sx={{ color: "black" }}>
                  $
                  {(
                    (invoiceDetails?.subTotal || 0) *
                    ((invoiceDetails?.HST || 0) / 100)
                  ).toFixed(2)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography fontWeight="bold" color="black">
                  GST ({invoiceDetails?.GST}%):
                </Typography>
                <Typography sx={{ color: "black" }}>
                  $
                  {(
                    (invoiceDetails?.subTotal || 0) *
                    ((invoiceDetails?.GST || 0) / 100)
                  ).toFixed(2)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography fontWeight="bold" color="black">
                  Platform Charges ({invoiceDetails?.PL_Charges}%):
                </Typography>
                <Typography sx={{ color: "black" }}>
                  ${(invoiceDetails?.PL_Charges ?? 0).toFixed(2)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography fontWeight="bold" color="black">
                  Extra Charges:
                </Typography>
                <Typography sx={{ color: "black" }}>
                  ${(invoiceDetails?.EX_Charges ?? 0).toFixed(2)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography fontWeight="bold" color="black">
                  Shipping Fee:
                </Typography>
                <Typography sx={{ color: "black" }}>
                  ${(invoiceDetails?.shippingFee ?? 0).toFixed(2)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderTop: "1px solid gray",
                  pt: 1,
                  mt: 2,
                }}
              >
                <Typography fontWeight="bold" color="black">
                  Total:
                </Typography>
                <Typography fontWeight="bold" sx={{ color: "black" }}>
                  ${(invoiceDetails?.total ?? 0).toFixed(2)}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              bgcolor: "black",
              display: "flex",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <Box sx={{ display: "flex", gap: 2, color: "white" }}>
              <IconButton href="mailto:store@sotrenearme.ca" color="inherit">
                <EmailIcon />
                <span style={{ fontSize: "15px" }}>store@sotrenearme.ca</span>
              </IconButton>
              <IconButton href="tel:+14167592942" color="inherit">
                <PhoneIcon />
                <span style={{ fontSize: "15px" }}>+14167592942</span>
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
});

InvoiceComponent.displayName = "InvoiceComponent";
export default InvoiceComponent;
