import instance from "../axios";
import { getLocalStorageItem } from "../utils/localStorage";

export const getSecretAccess = async (loginData) => {
  try {
    let response = await instance.post("/auth/getSecretAccess", loginData);
    return response;
  } catch (err) {
    return err;
  }
};

export const uploadFiles = async (files) => {
  try {
    let response = await instance.post("/file/upload", files, {
      headers: {
        accesskeyid: getLocalStorageItem("accessKeyId"),
        Authorization: getLocalStorageItem("secretAccessKey")
      }
    });
    return response;
  } catch (err) {
    console.error(err);
    return err;
  }
};
